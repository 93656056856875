import React from 'react'
import Godown from '../../SuperAdmin/Inventory/Godowns/Godown'
import AdminTopbar from '../Layout/AdminTopbar'
const Godowns = () => {
    return (
        <>
            <AdminTopbar activeTab={'godown'} />
            <Godown />
        </>
    )
}

export default Godowns