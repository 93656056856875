import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const addStock = createAsyncThunk(
    "admin/addProduct",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/stock-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the stock.' });
        }
    }
);
export const addStockSuper = createAsyncThunk(
    "super_admin/addStock",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the stock.' });
        }
    }
);

export const bulkStockAdd = createAsyncThunk(
    "stock/addBulkStock",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/stock-in-bulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the stock.' });
        }
    }
);
export const bulkStockAddSuper = createAsyncThunk(
    "stockSuper/addBulkStockSuper",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-in-bulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the stock.' });
        }
    }
);

export const stockUpdate = createAsyncThunk(
    "admin/produxtUpdate",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/stock-out`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while updating stock.' });
        }
    }
);
export const superStockOut = createAsyncThunk(
    "super_admin/productUpdate",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-out`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while updating stock.' });
        }
    }
);

export const superStockUpdate = createAsyncThunk(
    "super_admin/stockUpdate",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-in-update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while updating stock.' });
        }
    }
);

export const stockReturn = createAsyncThunk(
    "admin/productReturn",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/return`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while returning stock.' });
        }
    }
);
export const stockReturnSuper = createAsyncThunk(
    "super-admin/productReturns",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/return`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while returning stock.' });
        }
    }
);
export const stockBroke = createAsyncThunk(
    "admin/productBroke",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/demage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while removing stock.' });
        }
    }
);
export const stockBrokeSuper = createAsyncThunk(
    "super-admin/productBrokes",
    async (stockData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/demage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(stockData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while removing stock.' });
        }
    }
);

export const deleteStock = createAsyncThunk(
    "deleteStock",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/stock-delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    addStatus: 'idle',
    superInStatus: 'idle',
    superOutStatus: 'idle',
    status: 'idle',
    bulkStatus: 'idle',
    updateStatus: 'idle',
    returnStatus: 'idle',
    brokeStatus: 'idle',
    deleteStat: 'idle',
    error: null,
};

const stockSlice = createSlice({
    name: 'stock',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addStock.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addStock.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
            })
            .addCase(addStock.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addStockSuper.pending, (state) => {
                state.superInStatus = 'pending';
            })
            .addCase(addStockSuper.fulfilled, (state, action) => {
                state.superInStatus = 'succeeded';
            })
            .addCase(addStockSuper.rejected, (state, action) => {
                state.superInStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(superStockUpdate.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(superStockUpdate.fulfilled, (state, action) => {
                state.status = 'succeeded';
            })
            .addCase(superStockUpdate.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(bulkStockAdd.pending, (state) => {
                state.bulkStatus = 'pending';
            })
            .addCase(bulkStockAdd.fulfilled, (state, action) => {
                state.bulkStatus = 'succeeded';
            })
            .addCase(bulkStockAdd.rejected, (state, action) => {
                state.bulkStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(bulkStockAddSuper.pending, (state) => {
                state.bulkStatus = 'pending';
            })
            .addCase(bulkStockAddSuper.fulfilled, (state, action) => {
                state.bulkStatus = 'succeeded';
            })
            .addCase(bulkStockAddSuper.rejected, (state, action) => {
                state.bulkStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(stockUpdate.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(stockUpdate.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(stockUpdate.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(superStockOut.pending, (state) => {
                state.superOutStatus = 'pending';
            })
            .addCase(superStockOut.fulfilled, (state, action) => {
                state.superOutStatus = 'succeeded';
            })
            .addCase(superStockOut.rejected, (state, action) => {
                state.superOutStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(stockReturn.pending, (state) => {
                state.returnStatus = 'pending';
            })
            .addCase(stockReturn.fulfilled, (state, action) => {
                state.returnStatus = 'succeeded';
            })
            .addCase(stockReturn.rejected, (state, action) => {
                state.returnStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(stockReturnSuper.pending, (state) => {
                state.returnStatus = 'pending';
            })
            .addCase(stockReturnSuper.fulfilled, (state, action) => {
                state.returnStatus = 'succeeded';
            })
            .addCase(stockReturnSuper.rejected, (state, action) => {
                state.returnStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(stockBroke.pending, (state) => {
                state.brokeStatus = 'pending';
            })
            .addCase(stockBroke.fulfilled, (state, action) => {
                state.brokeStatus = 'succeeded';
            })
            .addCase(stockBroke.rejected, (state, action) => {
                state.brokeStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(stockBrokeSuper.pending, (state) => {
                state.brokeStatus = 'pending';
            })
            .addCase(stockBrokeSuper.fulfilled, (state, action) => {
                state.brokeStatus = 'succeeded';
            })
            .addCase(stockBrokeSuper.rejected, (state, action) => {
                state.brokeStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteStock.pending, (state) => {
                state.deleteStat = 'pending';
            })
            .addCase(deleteStock.fulfilled, (state, action) => {
                state.deleteStat = 'succeeded';
            })
            .addCase(deleteStock.rejected, (state, action) => {
                state.deleteStat = 'failed';
                state.error = action.payload;
            });
    }
});

export default stockSlice.reducer;
