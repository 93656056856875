import AddUsers from './AddUsers';
import { getRoles } from '../../../redux/Slices/SuperAdmin/rolesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUsers, userDelete } from '../../../redux/Slices/SuperAdmin/userSlice';
import { FaTrash, FaPencilAlt, FaRegEdit, FaTimes } from 'react-icons/fa';
import UpdateUser from './UpdateUser';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import Loader from '../../../components/utils/Loader';
import Topbar from '../../../components/Layout/Topbar';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const User = () => {

    const { roleId } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { AllUsers, getStatus, addStatus, updateStatus, deleteStatus, error } = useSelector((state) => state.user);
    const [addForm, setAddForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredUsers, setFilteredUsers] = useState(AllUsers);
    const [selectedUser, setSelectedUser] = useState({});
    const [prevStatus, setPrevStatus] = useState({ add: '', update: '', delete: '' });
    const [visiblePasswords, setVisiblePasswords] = useState({});

    useEffect(() => {
        if (addStatus === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('User added successfully!');
            setPrevStatus({ ...prevStatus, add: addStatus });
        } else if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('User updated successfully!');
            setPrevStatus({ ...prevStatus, update: updateStatus });
        } else if (deleteStatus === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('User deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStatus });
        } else {
            setPrevStatus({ add: addStatus, update: updateStatus, delete: deleteStatus });
        }
    }, [addStatus, updateStatus, deleteStatus]);

    useEffect(() => {
        dispatch(getUsers());
    }, [dispatch, addStatus, updateStatus, deleteStatus]);

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredUsers = AllUsers.filter(user =>
            String(user.name).toLowerCase().includes(lowerCaseQuery) ||
            String(user.email).toLowerCase().includes(lowerCaseQuery) ||
            String(user.role).toLowerCase().includes(lowerCaseQuery) ||
            String(user.empCode).toLowerCase().includes(lowerCaseQuery) ||
            (lowerCaseQuery == 'active' && user.is_active == 1) ||
            (lowerCaseQuery == 'inactive' && user.is_active == 0) ||
            String(user.phone).toLowerCase().includes(lowerCaseQuery)
        );

        const sortedUsers = filteredUsers.sort((a, b) => {
            return String(a.empCode).localeCompare(String(b.empCode));
        });

        setFilteredUsers(sortedUsers);
    }, [searchQuery, AllUsers]);

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setUpdateForm(true);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change


    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'All' ? filteredUsers.length : parseInt(value, 10));
        setPage(0);
    };

    const isPending = getStatus === 'pending' || updateStatus === 'pending' || addStatus === 'pending' || deleteStatus === 'pending';

    useEffect(() => {
        if (addStatus === 'succeeded' || updateStatus === 'succeeded') {
            setAddForm(false);
            setUpdateForm(false);
        }
    }, [addStatus, updateStatus]);

    const togglePasswordVisibility = (index) => {
        setVisiblePasswords((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    return (
        <>
            {isPending && (
                <Loader />
            )}
            <Topbar activeTab={'users'} />
            <ToastContainer theme='dark' />
            {addForm && (

                <div className="fixed z-10 inset-0 flex items-center justify-center mt-12 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg  w-[50rem]">
                        <button
                            className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
                            onClick={() => setAddForm(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <AddUsers />
                    </div>
                </div>
            )}
            {updateForm && (

                <div className="fixed z-10 inset-0 flex items-center justify-center mt-12 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-[40rem]">
                        <button
                            className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
                            onClick={() => setUpdateForm(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <UpdateUser user={selectedUser} />
                    </div>
                </div>
            )}

            <div className="p-3 relative overflow-x-hidden">

                <span className='flex items-center justify-between bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg'>
                    <div className="mb-4 m-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-2 px-4  border border-gray-400"
                            placeholder="Search by name, email, role, or emp code"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}

                        />
                    </div>
                    <div className="flex justify-end m-5 mb-4 gap-5">
                        <button className="bg-orange-600 hover:bg-orange-700 text-white  py-2 px-4 rounded shadow-md"
                            onClick={() => setAddForm(true)}
                            disabled={roleId === '4'}
                        >
                            Add User
                        </button>
                        {/* <button className="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded shadow-md"
                            onClick={() => navigate('/roles')}
                            disabled={roleId === '4'}
                        >
                            Roles
                        </button> */}
                    </div>
                </span>
                <Paper >
                    <TableContainer>
                        <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                            <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                    <TableCell sx={{ color: 'white', width: '130px' }}>EMP Code</TableCell>
                                    <TableCell sx={{ color: 'white', width: '200px' }}>Godown</TableCell>
                                    <TableCell sx={{ color: 'white', width: '200px' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'white', width: '260px' }}>Email</TableCell>
                                    <TableCell sx={{ color: 'white', width: '150px' }}>Phone</TableCell>
                                    <TableCell sx={{ color: 'white', width: '150px' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', width: '150px' }}>Role</TableCell>
                                    {roleId === '1' && (
                                        <>
                                            <TableCell sx={{ color: 'white', width: '150px' }}>Password</TableCell>
                                            <TableCell sx={{ color: 'white', width: '40px' }}></TableCell>
                                            <TableCell sx={{ color: 'white', width: '150px' }}>Action</TableCell>
                                        </>
                                    )}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <TableRow key={item.id} hover>
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell>{item.empCode}</TableCell>
                                        <TableCell>{item.godownsname}</TableCell>
                                        <TableCell>{item.name} {item.last_name}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.phone}</TableCell>
                                        <TableCell>{item.is_active === 1 ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell>{item.rolename}</TableCell>
                                        {roleId === '1' && (
                                            <>
                                                <TableCell>{visiblePasswords[index] ? item.use_password : '*******'}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => togglePasswordVisibility(index)}
                                                        aria-label={visiblePasswords[index] ? 'Hide password' : 'Show password'}
                                                    >
                                                        {visiblePasswords[index] ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="flex">
                                                        <IconButton disabled={roleId === '4'} onClick={() => handleUserClick(item)} aria-label="edit">
                                                            <FaRegEdit className="text-blue-700" />
                                                        </IconButton>
                                                        <IconButton disabled={roleId === '4'} onClick={() => dispatch(userDelete(item.id))} aria-label="delete">
                                                            <FaTrash className="text-red-600" />
                                                        </IconButton>
                                                    </span>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination Component */}
                    <TablePagination
                        component="div"
                        count={filteredUsers.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100, 'All']}
                    />
                </Paper>
            </div>

        </>
    )
}

export default User