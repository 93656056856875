import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getFinish = createAsyncThunk(
    "super_admin/getFinsih",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/finish`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addFinish = createAsyncThunk(
    "addFinish",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/finish/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateFinish = createAsyncThunk(
    "updateFinish",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/finish/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const finishDelete = createAsyncThunk(
    "finishDelete",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/finish/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const superAdminFinishSlice = createSlice({
    name: 'finish',
    initialState: {
        finishData: [],
        getStatus: 'idle',
        addStatus: 'idle',
        updateStatus: 'idle',
        deleteStatus: 'idle',
        error: null,
        category: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFinish.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getFinish.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.finishData = action.payload.data;
            })
            .addCase(getFinish.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addFinish.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addFinish.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.category = action.payload;
            })
            .addCase(addFinish.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateFinish.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateFinish.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                state.category = action.payload;
            })
            .addCase(updateFinish.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(finishDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(finishDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(finishDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default superAdminFinishSlice.reducer;
