import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getAdminProduct = createAsyncThunk(
    "admin/getProduct",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addAdminProduct = createAsyncThunk(
    "admin/addProduct",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const product = productData.products[0];

            const formData = new FormData();


            if (product.image && typeof product.image !== 'string') {
                formData.append('image', product.image);
            }

            const productWithoutImage = {
                name: product.name,
                sku: product.sku,
                size: product.size,
                qty: product.qty,
                godowns_id: product.godowns_id,
                uom: product.uom,
                product_in_date: product.product_in_date,
                color: product.color,
                brand: product.brand,
                category_id: product.category_id,
                sub_category: product.sub_category,
                thickness: product.thickness,
                finish: product.finish
            };

            Object.entries(productWithoutImage).forEach(([key, value]) => {
                formData.append(key, value);
            });

            const response = await fetch(`${apiUrl}admin/product/add`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the product.' });
        }
    }
);



export const productAdminUpdate = createAsyncThunk(
    "admin/productUpdate",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            // Append product data to FormData
            formData.append('id', productData.id);
            formData.append('name', productData.name);
            formData.append('brand', productData.brand);
            formData.append('category_id', productData.category_id);
            formData.append('sub_category', productData.sub_category);
            formData.append('size', productData.size);
            formData.append('uom', productData.uom);
            formData.append('product_in_date', productData.product_in_date);
            formData.append('color', productData.color);
            formData.append('description', productData.desc);
            formData.append('thickness', productData.thickness);
            formData.append('finish', productData.finish);
            formData.append('dis_continue', productData.dis_continue);

            // Append image if it is provided and is a File object
            if (productData.image && productData.image instanceof File) {
                formData.append('image', productData.image);
            }

            const response = await fetch(`${apiUrl}admin/product/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while updating the product.' });
        }
    }
);

export const productAdminDelete = createAsyncThunk(
    "productdelete",
    async (id, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    adminAddStatus: 'idle',
    AdminProducts: [],
    adminGetStatus: 'idle',
    adminUpdateStatus: 'idle',
    adminDeleteStatus: 'idle',
    error: null,
    product: null,
};

const adminProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdminProduct.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getAdminProduct.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.AdminProducts = action.payload.data;
            })
            .addCase(getAdminProduct.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addAdminProduct.pending, (state) => {
                state.adminAddStatus = 'pending';
            })
            .addCase(addAdminProduct.fulfilled, (state, action) => {
                state.adminAddStatus = 'succeeded';
                state.product = action.payload;
            })
            .addCase(addAdminProduct.rejected, (state, action) => {
                state.adminAddStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productAdminUpdate.pending, (state) => {
                state.adminUpdateStatus = 'pending';
            })
            .addCase(productAdminUpdate.fulfilled, (state, action) => {
                state.adminUpdateStatus = 'succeeded';
            })
            .addCase(productAdminUpdate.rejected, (state, action) => {
                state.adminUpdateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productAdminDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(productAdminDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(productAdminDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default adminProductSlice.reducer;
