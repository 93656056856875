import React, { useEffect, useState } from 'react';
import AdminTopbar from '../Layout/AdminTopbar';
import { useDispatch, useSelector } from 'react-redux';
import { getReturned } from '../../../redux/Slices/Report/reportSlice';
import { getAdminProduct } from '../../../redux/Slices/Admin/adminProductSlice';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
    InputLabel,
    FormControl,
    MenuItem,
    TextField,
    Select,
    IconButton,
    Typography,
    Modal,
    Box,
} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import MessageIcon from '@mui/icons-material/Message';
import Loader from '../../../components/utils/Loader';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { AiOutlinePicture } from 'react-icons/ai';
import { getBrand } from '../../../redux/Slices/SuperAdmin/inventory/brandSlice';
import { getSize } from '../../../redux/Slices/Measures/sizeSlice';
import { getCategory } from '../../../redux/Slices/SuperAdmin/categorySlice';
import { getGodown } from '../../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { getFinish } from '../../../redux/Slices/SuperAdmin/finishSlice';

const RetunredIn = () => {
    const { returnData = [], status, error } = useSelector((state) => state.report);
    const { AdminProducts = [], adminGetStatus } = useSelector(state => state.adminProduct);
    const [combinedData, setCombinedData] = useState([]);
    const [page, setPage] = useState(0);
    const roleID = localStorage.getItem('roleId')
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [currentRemarks, setCurrentRemarks] = useState('');
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedFinish, setSelectedFinish] = useState('');
    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const { brandData = [] } = useSelector(state => state.superAdminBrand);
    const { sizeData = [] } = useSelector(state => state.superAdminSize);
    const { data = [] } = useSelector(state => state.superAdminCategory);
    const { finishData = [] } = useSelector(state => state.finish);

    useEffect(() => {
        dispatch(getReturned());
        dispatch(getAdminProduct());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBrand());
        dispatch(getSize());
        dispatch(getCategory());
        dispatch(getFinish());
    }, [])


    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };
    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleFinishChange = (event) => {
        setSelectedFinish(event.target.value);
    };

    useEffect(() => {
        if (returnData.length && AdminProducts.length) {
            const combined = returnData.map(returnItem => {
                // Find the product that contains the inventory with the matching id
                const productWithInventory = AdminProducts.find(product =>
                    product.inventories.some(inventory => inventory.id === Number(returnItem.inventories_id))
                );

                if (productWithInventory) {
                    // Find the matching inventory using the inventories_id from returnItem
                    const matchingInventory = productWithInventory.inventories.find(inventory =>
                        inventory.id === Number(returnItem.inventories_id)
                    );

                    return {
                        inventories_id: returnItem.inventories_id,
                        remarks: returnItem.remarks,
                        return_qty: returnItem.qty,
                        product_date: returnItem.product_date,
                        total_qty: matchingInventory ? matchingInventory.qty : null,
                        batch_id: matchingInventory ? matchingInventory.batch_id : null,
                        sku: productWithInventory.sku,
                        name: productWithInventory.name,
                        size: productWithInventory.size,
                        color: productWithInventory.color,
                        brandname: productWithInventory.brandname,
                        uom: productWithInventory.uom,
                        finish: productWithInventory.finish,
                        thickness: productWithInventory.thickness,
                        subcategoriesname: productWithInventory.subcategoriesname,
                        categoriename: productWithInventory.categoriename,
                        ImageUrl: productWithInventory.ImageUrl
                    };
                }
                return null;
            }).filter(item => item !== null);

            setCombinedData(combined);
        }
    }, [returnData, AdminProducts]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? filteredData.length : parseInt(value, 10));
        setPage(0);
    };

    const filteredData = combinedData.filter(item => {
        const itemDate = new Date(item.product_date);
        const from = new Date(fromDate);
        const to = new Date(toDate);

        if (toDate) {
            to.setDate(to.getDate() + 1);
        }

        const isWithinDateRange = (!fromDate || itemDate >= from) && (!toDate || itemDate < to);

        return (
            (item.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.subcategoriesname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.color?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.batch_id.toLowerCase().includes(searchQuery.toLowerCase())) &&
            (!selectedSize || (item.size && item.size === selectedSize)) &&
            (!selectedBrand || (item.brandname && item.brandname === selectedBrand)) &&
            (!selectedCategory || (item.categoriename && item.categoriename === selectedCategory)) &&
            (!selectedFinish || (item.finish && item.finish === selectedFinish)) &&
            isWithinDateRange
        );
    });

    const handleOpenModal = (remarks) => {
        setCurrentRemarks(remarks);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const downloadCSV = () => {
        const csvRows = [];
        csvRows.push(['SKU', 'NAME', 'RETURN QTY', 'BRAND', 'CATEGORY', 'SUB-CATEGORY', 'BATCH', 'SIZE', 'COLOR', 'UOM', 'FINISH', 'THICKNESS', 'DATE']);

        const dataForCSV = combinedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

        dataForCSV.map((item) => {
            csvRows.push([
                item.sku,
                item.name,
                item.return_qty,
                item.brandname,
                item.categoriename,
                item.subcategoriesname,
                item.batch_id || 'N/A',
                item.size,
                item.color,
                item.uom,
                item.finish,
                item.thickness,
                new Date(item.product_date).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                }),
            ]);
        });

        const csvString = csvRows.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'return_report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div style={{ padding: '20px', background: 'white', margin: '100px auto', maxWidth: '500px', borderRadius: '8px' }}>
                    <Typography id="modal-title" variant="h6">Remarks</Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {currentRemarks || 'No remarks available'}
                    </Typography>
                </div>
            </Modal>
            {(adminGetStatus === 'pending' || status === 'pending') && <Loader />}
            <AdminTopbar activeTab={'return report'} />
            <div className="p-3 bg-gray-50 shadow-lg rounded-lg">
                <div className="flex justify-between items-center mb-4 bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg p-5">
                    <span className="flex flex-col w-full">

                        {/* top filter */}
                        <Box display="flex" flexDirection="row" paddingTop={1} paddingBottom={2} gap={2}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="brand-select-label" sx={{ color: '#FFFFFF' }}>Brand</InputLabel>
                                <Select
                                    labelId="brand-select-label"
                                    value={selectedBrand}
                                    onChange={handleBrandChange}
                                    label="Brand"
                                    sx={{
                                        bgcolor: '#90E00',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Brands</em></MenuItem>
                                    {brandData.map(brand => (
                                        <MenuItem key={brand.id} value={brand.name}>{brand.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="category-select-label" sx={{ color: '#FFFFFF' }}>Category</InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    label="Category"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Categories</em></MenuItem>
                                    {data.map(category => (
                                        <MenuItem key={category.id} value={category.name}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="size-select-label" sx={{ color: '#FFFFFF' }}>size</InputLabel>
                                <Select
                                    labelId="size-select-label"
                                    value={selectedSize}
                                    onChange={handleSizeChange}
                                    label="Size"
                                    sx={{
                                        bgcolor: '#90E00',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Sizes</em></MenuItem>
                                    {sizeData.map(size => (
                                        <MenuItem key={size.id} value={size.name}>{size.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="finish-select-label" sx={{ color: '#FFFFFF' }}>Finish</InputLabel>
                                <Select
                                    labelId="finish-select-label"
                                    value={selectedFinish}
                                    onChange={handleFinishChange}
                                    label="Finish"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root ```javascript': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Finishes</em></MenuItem>
                                    {finishData.map(finish => (
                                        <MenuItem key={finish.id} value={finish.name}>{finish.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <span className='flex items-center justify-between -mt-4"' >
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className='border border-blue-600 rounded-lg w-[20rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400'
                                placeholder="Search"
                            />

                            <div className="flex items-center">
                                <input
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    className='border border-blue-600 rounded-lg w-[12rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400'
                                />

                                <MultipleStopIcon className="text-white mx-2" />

                                <input
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    className='border border-blue-600 rounded-lg w-[12rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400'
                                />
                            </div>

                            <Button
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                onClick={downloadCSV}
                                className="bg-blue-600  text-white hover:bg-blue-700 transition-colors"
                            >
                                Download
                            </Button>
                        </span>
                    </span>
                </div>
                <TableContainer component={Paper} className="rounded-lg mt-[-2rem] shadow-md overflow-hidden">
                    <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                            <TableRow className="">
                                <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                <TableCell sx={{ color: 'white', width: '140px' }} className="text-center text-xs font-bold py-3">SKU</TableCell>
                                <TableCell sx={{ color: 'white', width: '240px' }} className="text-center text-xs font-bold py-3">Name</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }} className="text-center text-xs font-bold py-3">Brand</TableCell>
                                <TableCell sx={{ color: 'white', width: '160px' }} className="text-center text-xs font-bold py-3">Category</TableCell>
                                <TableCell sx={{ color: 'white', width: '160px' }} className="text-center text-xs font-bold py-3">Sub-Category</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }} className="text-center text-xs font-bold py-3">Batch</TableCell>
                                <TableCell sx={{ color: 'white', width: '120px' }} className="text-center text-xs font-bold py-3">Size</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }} className="text-center text-xs font-bold py-3">Color</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Return QTY</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">UOM</TableCell>
                                <TableCell sx={{ color: 'white', width: '160px' }} className="text-center text-xs font-bold py-3">Finish</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Thickness</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Remarks</TableCell>
                                <TableCell sx={{ color: 'white', width: '120px' }} className="text-center text-xs font-bold py-3">Date</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Image</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                <TableRow key={item.sku + index} className="hover:bg-gray-100 transition-all text-sm">
                                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                    <TableCell className="text-center py-1">{item.sku}</TableCell>
                                    <TableCell className="text-center py-1">{item.name}</TableCell>
                                    <TableCell className="text-center py-1">{item.brandname}</TableCell>
                                    <TableCell className="text-center py-1">{item.categoriename}</TableCell>
                                    <TableCell className="text-center py-1">{item.subcategoriesname}</TableCell>
                                    <TableCell className="text-center py-1">{item.batch_id || 'N/A'}</TableCell>
                                    <TableCell className="text-center py-1">{item.size}</TableCell>
                                    <TableCell className="text-center py-1">{item.color}</TableCell>
                                    <TableCell className="text-center py-1">{item.return_qty}</TableCell>
                                    <TableCell className="text-center py-1">{item.uom}</TableCell>
                                    <TableCell className="text-center py-1">{item.finish}</TableCell>
                                    <TableCell className="text-center py-1">{item.thickness}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenModal(item.remarks)}>
                                            <MessageIcon />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell className="text-center py-1">
                                        {new Date(item.product_date).toLocaleDateString('en-GB', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        {item.ImageUrl !== 'https://inventory.risapp.co.in/public/storage' && (
                                            <div className="cursor-pointer h-1 w-1 items-center" onClick={() => setShowImage(item.ImageUrl)}>
                                                <AiOutlinePicture size={24} />

                                                {showImage === item.ImageUrl && (
                                                    // Modal Background
                                                    <div
                                                        className="fixed inset-0 z-20 flex  items-center justify-center bg-black bg-opacity-10"
                                                        onClick={() => setShowImage(false)}
                                                    >
                                                        {/* Modal Content */}
                                                        <div
                                                            className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                                            style={{ width: '80%', maxWidth: '500px' }}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >

                                                            {/* Image */}
                                                            <div className="flex justify-center">
                                                                <img
                                                                    src={item.ImageUrl}
                                                                    alt="Image"
                                                                    className="w-full h-full object-cover rounded-lg"
                                                                    style={{ maxHeight: '70vh' }}
                                                                />
                                                            </div>

                                                            {/* Action Buttons */}
                                                            <div className="mt-4 flex justify-center space-x-4">
                                                                <button
                                                                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                    onClick={() => setShowImage(false)}
                                                                >
                                                                    Close
                                                                </button>
                                                                <button
                                                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                    onClick={() => window.open(item.ImageUrl, '_blank')}
                                                                >
                                                                    Open in New Tab
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="mt-4"
                />
            </div>
        </>
    );
};

export default RetunredIn;
