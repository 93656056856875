import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getGodown = createAsyncThunk(
    "super_admin/getGodown",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/godowns`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addGodown = createAsyncThunk(
    "addGodown",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/godowns/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateGodown = createAsyncThunk(
    "updateGodown",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/godowns/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const deleteGodown = createAsyncThunk(
    "deleteGodown",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/godowns/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);


const superAdminGodownSlice = createSlice({
    name: 'godown',
    initialState: {
        godownData: [],
        getStatus: 'idle',
        addStatus: 'idle',
        updateStatus: 'idle',
        deleteStatus: 'idle',
        error: null,
        godown: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGodown.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getGodown.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.godownData = action.payload.data;
            })
            .addCase(getGodown.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addGodown.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addGodown.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.godown = action.payload;
            })
            .addCase(addGodown.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateGodown.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateGodown.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                state.godown = action.payload;
            })
            .addCase(updateGodown.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteGodown.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteGodown.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
                state.godown = action.payload;
            })
            .addCase(deleteGodown.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default superAdminGodownSlice.reducer;
