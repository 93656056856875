import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getUsers = createAsyncThunk(
    "super_admin/getUsers",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/user`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addUser = createAsyncThunk(
    "super_admin/addUser",
    async (userData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/user/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the user.' });
        }
    }
);

export const userUpdate = createAsyncThunk(
    "super_admin/userUpdate",
    async (userData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/user/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(userData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the user.' });
        }
    }
);

export const userDelete = createAsyncThunk(
    "userdelete",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/user/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    addStatus: 'idle',
    AllUsers: [],
    getStatus: 'idle',
    updateStatus: 'idle',
    deleteStatus: 'idle',
    error: null,
    user: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.AllUsers = action.payload.data;
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addUser.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.user = action.payload;
            })
            .addCase(addUser.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(userUpdate.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(userUpdate.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(userUpdate.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(userDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(userDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(userDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default userSlice.reducer;
