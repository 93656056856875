import React from 'react'
import Product from '../../Products/Product'
import AdminTopbar from '../Layout/AdminTopbar'

const Products = () => {
    return (
        <>
            <AdminTopbar activeTab={'Products'} />
            <Product />
        </>
    )
}

export default Products