import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegEdit, FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import { addFinish, finishDelete, getFinish, updateFinish } from '../../redux/Slices/SuperAdmin/finishSlice';
import Topbar from '../../components/Layout/Topbar';
import Loader from '../../components/utils/Loader';
import AdminSidebar from '../Admin/Layout/AdminSidebar';
import AdminTopbar from '../Admin/Layout/AdminTopbar';

const Finish = () => {

    const { finishData = [], getStatus, updateStatus, addStatus, deleteStatus } = useSelector(state => state.finish);
    const [categoryName, setCategoryName] = useState('');
    const [updatedCategoryName, setUpdatedCategoryName] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(finishData);
    const [updateForm, setUpdateForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const dispatch = useDispatch();
    const { roleId } = useSelector((state) => state.auth);
    const [prevStatus, setPrevStatus] = useState({ add: '', update: '', delete: '' });

    useEffect(() => {
        if (addStatus === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('Finish added successfully!');
            setPrevStatus({ ...prevStatus, add: addStatus });
        } else if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Finish updated successfully!');
            setPrevStatus({ ...prevStatus, update: updateStatus });
        } else if (deleteStatus === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('Finish deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStatus });
        } else {
            setPrevStatus({ add: addStatus, update: updateStatus, delete: deleteStatus });
        }
    }, [addStatus, updateStatus, deleteStatus]);

    useEffect(() => {
        dispatch(getFinish());
    }, [dispatch, updateStatus, addStatus, deleteStatus]);

    const handleAddCategory = (e) => {
        e.preventDefault();
        dispatch(addFinish({ name: categoryName }));
        setCategoryName('');
    };

    const handleUpdateCategory = (e) => {
        e.preventDefault();
        dispatch(updateFinish({ id: selectedItem.id, name: updatedCategoryName }));
        setUpdatedCategoryName('');
    };

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredData(
            finishData.filter(cat =>
                String(cat.name).toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery, finishData]);

    const handleItemClick = (category) => {
        setSelectedItem(category);
        setUpdateForm(true);
    };



    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? filteredData.length : parseInt(value, 10));
        setPage(0);
    };

    const isPending = getStatus === 'pending' || updateStatus === 'pending' || addStatus === 'pending' || deleteStatus === 'pending';

    useEffect(() => {
        if (addStatus === 'succeeded' || updateStatus === 'succeeded') {
            setUpdateForm(false);
        }
    }, [addStatus, updateStatus]);

    return (
        <>
            {(roleId === '1' || roleId === '4') ? (
                <Topbar activeTab={'finish'} />
            ) : (
                <AdminTopbar activeTab={'finish'} />
            )}
            {isPending && (
                <Loader />
            )}

            <ToastContainer theme='dark' />

            {/* category update modal */}
            {updateForm && (

                <div className="fixed z-10 inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <div className="flex justify-between mb-4">
                            <h2 className="font-semibold text-lg">Update Finish</h2>
                            <button
                                className="bg-gray-700 rounded-full p-1 hover:text-gray-300 text-white"
                                onClick={() => setUpdateForm(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="mb-4">
                            <p className="text-gray-600">Selected Finish:</p>
                            <p className="text-lg">{selectedItem.id} - {selectedItem.name}</p>
                        </div>

                        <form onSubmit={handleUpdateCategory}>
                            <div className="flex items-center mb-4 gap-4">
                                <input
                                    type="text"
                                    value={updatedCategoryName}
                                    onChange={(e) => setUpdatedCategoryName(e.target.value)}
                                    placeholder="Enter updated finsih"
                                    className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                                />
                                <button
                                    type="submit"
                                    className="bg-orange-500 py-3 hover:bg-orange-700 text-sm text-white font-bold px-4 rounded ml-2"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className='p-5'>

                {/* Search and Add Category Section */}
                <span className='flex items-center justify-between bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg py-4'>
                    <div className="mb-4 ml-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-3 px-4 border border-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            placeholder="Search by any field"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    {roleId !== '4' && (
                        <div className="flex justify-end mr-5 mb-4 gap-4">
                            <form onSubmit={handleAddCategory}>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="text"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        placeholder="Enter finish name"
                                        className="w-[18rem] p-2 py-3 pl-4 text-sm text-gray-700 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-400"
                                    />
                                    <button
                                        type="submit"
                                        className="bg-orange-500 py-3 px-5 text-white rounded hover:bg-orange-700 transition-all duration-300"
                                    >
                                        Add
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </span>

                {/* Category List Table */}
                <Paper className="mt-5 shadow-md">
                    <TableContainer>
                        <Table>
                            <TableHead className='bg-gradient-to-r from-gray-600 to-gray-700'>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>SL No.</TableCell>
                                    <TableCell sx={{ color: 'white' }}>ID</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Finish</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Created At</TableCell>
                                    {roleId !== '4' && (
                                        <TableCell sx={{ color: 'white' }}>Action</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((category, index) => (
                                        <TableRow key={category.id} className="hover:bg-gray-50 transition-colors">
                                            <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                            <TableCell>{category.id}</TableCell>
                                            <TableCell>{category.name}</TableCell>
                                            <TableCell>{new Date(category.created_at).toLocaleDateString('en-GB')}</TableCell>
                                            {roleId !== '4' && (
                                                <TableCell>
                                                    <div className="flex gap-2">
                                                        <IconButton onClick={() => handleItemClick(category)} >
                                                            <FaRegEdit className="text-blue-500 hover:text-blue-700" />
                                                        </IconButton>
                                                        <IconButton onClick={() => dispatch(finishDelete(category.id))} >
                                                            <FaTrash className="text-red-600 hover:text-red-800" />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                        className="bg-gray-50"
                    />
                </Paper>
            </div>

        </>
    );
};

export default Finish;