import { Snackbar, Alert } from '@mui/material';
import { CheckCircle, Error, Warning, Info } from '@mui/icons-material';
import { useEffect, useState } from 'react';

const Notification = ({ message, type }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {message && (
                <Snackbar
                    open={open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            borderRadius: '8px',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                            transition: 'transform 0.3s ease-in-out',
                        },
                    }}
                >
                    <Alert
                        severity={type}
                        iconMapping={{
                            success: <CheckCircle sx={{ fontSize: 20, color: '#fff' }} />,
                            error: <Error sx={{ fontSize: 20, color: '#fff' }} />,
                            warning: <Warning sx={{ fontSize: 20, color: '#fff' }} />,
                            info: <Info sx={{ fontSize: 20, color: '#fff' }} />,
                        }}
                        sx={{
                            backgroundColor: type === 'success' ? '#34C759' : type === 'error' ? '#FF3B30' : type === 'warning' ? '#FFC107' : '#007AFF',
                            color: '#fff',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                            padding: '16px',
                            textAlign: 'center',
                        }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default Notification;
