import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getSubCategory = createAsyncThunk(
    "super_admin/getSubCategory",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/sub-category`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addSubCategory = createAsyncThunk(
    "super_admin/addSubCategory",
    async (subCategoryData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/sub-category/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(subCategoryData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the subCategory.' });
        }
    }
);

export const subCategoryUpdate = createAsyncThunk(
    "super_admin/produxtUpdate",
    async (subCategoryData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            formData.append('id', subCategoryData.id);
            formData.append('name', subCategoryData.name);
            if (subCategoryData.image && subCategoryData.image instanceof File) {
                formData.append('image', subCategoryData.image);
            }
            const response = await fetch(`${apiUrl}super-admin/sub-category/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the subCategory.' });
        }
    }
);

export const subCategoryDelete = createAsyncThunk(
    "subCategorydelete",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/sub-category/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    addSubStat: 'idle',
    AllSubCategory: [],
    getStatus: 'idle',
    updateStat: 'idle',
    deleteStat: 'idle',
    error: null,
    subCategory: null,
};

const subCategorySlice = createSlice({
    name: 'subCategory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSubCategory.pending, (state) => {
                state.getSt = 'pending';
            })
            .addCase(getSubCategory.fulfilled, (state, action) => {
                state.getStat = 'succeeded';
                state.AllSubCategory = action.payload.data;
            })
            .addCase(getSubCategory.rejected, (state, action) => {
                state.getStat = 'failed';
                state.error = action.payload;
            })
            .addCase(addSubCategory.pending, (state) => {
                state.addSubStat = 'pending';
            })
            .addCase(addSubCategory.fulfilled, (state, action) => {
                state.addSubStat = 'succeeded';
                state.subCategory = action.payload;
            })
            .addCase(addSubCategory.rejected, (state, action) => {
                state.addSubStat = 'failed';
                state.error = action.payload;
            })
            .addCase(subCategoryUpdate.pending, (state) => {
                state.updateStat = 'pending';
            })
            .addCase(subCategoryUpdate.fulfilled, (state, action) => {
                state.updateStat = 'succeeded';
            })
            .addCase(subCategoryUpdate.rejected, (state, action) => {
                state.updateStat = 'failed';
                state.error = action.payload;
            })
            .addCase(subCategoryDelete.pending, (state) => {
                state.deleteStat = 'pending';
            })
            .addCase(subCategoryDelete.fulfilled, (state, action) => {
                state.deleteStat = 'succeeded';
            })
            .addCase(subCategoryDelete.rejected, (state, action) => {
                state.deleteStat = 'failed';
                state.error = action.payload;
            });
    }
});

export default subCategorySlice.reducer;
