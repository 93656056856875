import { FaBars, FaUserCircle, FaPowerOff } from 'react-icons/fa'; // Import React Icons
import { logout } from '../../../redux/Slices/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const AdminTopbar = ({ activeTab }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const name = localStorage.getItem('name');
    const location = useLocation();
    const currentPath = location.pathname;

    const onLogout = () => {
        dispatch(logout());
        navigate('/');
    }

    return (
        <nav className=" bg-gray-800 shadow-lg fixed top-0 left-0 right-0 z-30 p-4">
            <div className="container mx-auto flex justify-between items-center">
                {currentPath !== '/admin' && (
                    <div className="flex-1 text-center">
                        <span className="bg-gradient-to-r from-orange-500 to-yellow-500 ml-56 text-white px-3 py-1 rounded-full text-base font-bold uppercase shadow-md hover:scale-105 transition duration-300">{activeTab}</span>
                    </div>
                )}
                <div className="flex items-center space-x-4 ml-auto">
                    <FaUserCircle className="h-8 w-8 text-white" />
                    <span className="text-white font-medium">{name}</span>
                    <button
                        onClick={onLogout}
                        className="text-white hover:text-gray-400"
                    >
                        <FaPowerOff className="h-6 text-red-500 w-6 hover:scale-110 hover:text-red-400" />
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default AdminTopbar;
