// src/components/layout/Sidebar.js
import { Link } from 'react-router-dom';
import { FaBars, FaBox, FaUsers, FaHome, FaHeart, FaArchive, FaShoppingCart, FaTag, FaUserCog } from 'react-icons/fa';
import { useState } from 'react';


const GodownSidebar = ({ isOpen, toggleSidebar }) => {

    const [inventory, setInventory] = useState(false);


    return (
        <div className={`z-50 bg-gray-800 fixed text-white h-screen ${isOpen ? 'w-64' : 'w-20'} transition-all duration-300`}>
            <div className="flex flex-col h-full">
                {/* Header Section */}
                <div className="flex items-center justify-between p-4" onClick={toggleSidebar}>
                    {isOpen && (
                        <div className={`flex items-center  space-x-2 w-full`}>
                            <h1 className={`text-xl mt-1 cursor-pointer text-purple-200`}>Admin Panel</h1>
                        </div>
                    )}
                    {/* Toggle Button */}
                    <button
                        className={`text-white ${isOpen ? 'right-10' : 'left-5'} hover:text-gray-200 transition-colors p-2 rounded-full focus:outline-none absolute top-4`}
                        onClick={() => { toggleSidebar(); setInventory(!inventory) }}                    >
                        <FaBars className={`h-5 w-5 text-white transition duration-300 transform hover:scale-110 hover:rotate-45`} />
                    </button>
                </div>

                {/* Sidebar Links */}
                <ul className="flex-1 mt-7">
                    <Link
                        to="/$godown"
                        className={`flex items-center space-x-4 py-4 px-4 gap-4 hover:bg-purple-700 m-2 hover:text-white rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                    >
                        <FaHome className={`h-7 w-7 text-blue-500 hover:text-blue-700 transition duration-300 transform hover:scale-110`} />
                        {isOpen && <span className="font-medium  text-gray-200">Dashboard</span>}
                    </Link>

                    <Link
                        to="godown-products"
                        className={`flex items-center space-x-4 py-4 px-4 gap-4 hover:bg-purple-700 m-2 hover:text-white rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                    >
                        <FaBox className={`h-5 w-5 text-red-500 hover:text-red-700 transition duration-300 transform hover:scale-110`} />
                        {isOpen && <span className="font-medium  text-gray-200">All Products</span>}
                    </Link>

                    <Link
                        to="/warehouse"
                        className={`flex items-center space-x-4 py-4 px-4 gap-4 hover:bg-purple-700 m-2 hover:text-white rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                    >
                        <FaArchive className={`h-7 w-7 text-white hover:text-gray-300 transition duration-300 transform hover:scale-110`} />
                        {isOpen && <span className="font-medium  text-gray-200">Warehouse</span>}
                    </Link>

                </ul>
                {isOpen && (

                    <span className='text-xs m-2  mb-0' style={{ display: 'inline-flex', alignItems: 'center' }}> Made with  <FaHeart className="h-3 w-3 m-2 text-red-500" /> by Ruminate IT Solutions </span>
                )}
            </div>
        </div>
    );
};

export default GodownSidebar;