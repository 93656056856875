import React, { useEffect, useState } from 'react';
import { getGodown, addGodown, updateGodown, deleteGodown } from '../../../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableView from '../../../../components/utils/TableView';
import Notification from '../../../../components/utils/Notification';
import Topbar from '../../../../components/Layout/Topbar';

const Godown = () => {

    const { godownData = [], updateStatus, getStatus, addStatus, deleteStatus } = useSelector(state => state.superAdminGodown);
    const dispatch = useDispatch();
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');

    useEffect(() => {
        dispatch(getGodown());
    }, [dispatch, updateStatus, addStatus, deleteStatus]);

    useEffect(() => {
        if (updateStatus === 'succeeded') {
            setNotificationMessage('Godown updated successfully');
            setNotificationType('success');
        } else if (addStatus === 'succeeded') {
            setNotificationMessage('Godown added successfully');
            setNotificationType('success');
        } else if (deleteStatus === 'succeeded') {
            setNotificationMessage('Godown removed successfully');
            setNotificationType('success');
        }
        setShowNotification(true);
    }, [updateStatus, addStatus, deleteStatus]);

    return (
        <>
            <Topbar activeTab={'godowns'} />
            {showNotification && (
                <Notification
                    message={notificationMessage}
                    type={notificationType}
                />
            )}

            <TableView
                data={godownData}
                deleteThunk={deleteGodown}
                addThunk={addGodown}
                updateThunk={updateGodown}
                addStatus={addStatus}
                deleteStatus={deleteStatus}
                updateStatus={updateStatus}
                getStatus={getStatus}
            />

        </>
    );
};

export default Godown;