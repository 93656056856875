import React from 'react'
import AdminTopbar from '../Layout/AdminTopbar'
import StockinReport from '../../Report/StockinReport'

const InReport = () => {
    return (
        <>
            <AdminTopbar activeTab={'stock in report'} />
            <StockinReport />
        </>
    )
}

export default InReport