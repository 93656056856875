import { useDispatch, useSelector } from "react-redux"
import { FaTrash, FaRegEdit } from 'react-icons/fa';
import { useEffect, useState } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import Loader from "../../components/utils/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TableView = ({
    data,
    deleteThunk,
    addThunk,
    updateThunk,
    addStatus,
    updateStatus,
    deleteStatus,
    getStatus
}) => {

    const dispatch = useDispatch();
    const [categoryName, setCategoryName] = useState('');
    const [updatedCategoryName, setUpdatedCategoryName] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(data);
    const [updateForm, setUpdateForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const { roleId } = useSelector((state) => state.auth);
    const [prevStatus, setPrevStatus] = useState({ add: '', update: '', delete: '' });

    useEffect(() => {
        if (addStatus === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('Added successfully!');
            setPrevStatus({ ...prevStatus, add: addStatus });
        } else if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Updated successfully!');
            setPrevStatus({ ...prevStatus, update: updateStatus });
        } else if (deleteStatus === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('Deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStatus });
        } else {
            setPrevStatus({ add: addStatus, update: updateStatus, delete: deleteStatus });
        }
    }, [addStatus, updateStatus, deleteStatus]);

    const handleAddCategory = (e) => {
        e.preventDefault();
        dispatch(addThunk({ name: categoryName }));
        setCategoryName('');
    };

    const handleUpdateCategory = (e) => {
        e.preventDefault();
        dispatch(updateThunk({ id: selectedItem.id, name: updatedCategoryName }));

        setUpdatedCategoryName('');
    };

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredData(
            data.filter(user =>
                String(user.name).toLowerCase().includes(lowerCaseQuery) ||
                String(user.id).toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery, data]);

    const handleItemClick = (category) => {
        setSelectedItem(category);
        setUpdateForm(true);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? filteredData.length : parseInt(value, 10));
        setPage(0);
    };

    const isPending = getStatus === 'pending' || updateStatus === 'pending' || addStatus === 'pending' || deleteStatus === 'pending';

    useEffect(() => {
        if (addStatus === 'succeeded' || updateStatus === 'succeeded') {
            setUpdateForm(false);
        }
    }, [addStatus, updateStatus]);

    return (
        <>
            <ToastContainer theme="dark" />
            {isPending && (
                <Loader />
            )}



            {updateForm && (

                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <div className="flex justify-between mb-4">
                            <h2 className="font-semibold text-lg">Update Item</h2>
                            <button
                                className="bg-gray-700 rounded-full p-1 hover:text-gray-300 text-white"
                                onClick={() => setUpdateForm(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="mb-4">
                            <p className="text-gray-600">Selected Item:</p>
                            <p className="text-lg">{selectedItem.id} - {selectedItem.name}</p>
                        </div>

                        <form onSubmit={handleUpdateCategory}>
                            <div className="flex items-center mb-4 gap-4">
                                <input
                                    type="text"
                                    value={updatedCategoryName}
                                    onChange={(e) => setUpdatedCategoryName(e.target.value)}
                                    placeholder="Enter updated entry"
                                    className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                                />
                                <button
                                    type="submit"
                                    className="bg-orange-500 py-3 hover:bg-orange-700 text-sm text-white font-bold px-4 rounded ml-2"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className='p-5'>
                <span className='flex items-center justify-between bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg'>
                    <div className="flex justify-end m-5 mb-8 gap-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-[0.6rem] px-4  border border-gray-400"
                            placeholder="Search by by any field"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <div className="flex justify-end m-5 mb-3 gap-5">
                        <form onSubmit={handleAddCategory}>
                            <div className="flex items-center mb-4">
                                <input
                                    type="text"
                                    value={categoryName}
                                    onChange={(e) => setCategoryName(e.target.value)}
                                    placeholder="Enter item name"
                                    disabled={roleId === '4'}
                                    className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                                />
                                <button
                                    type="submit"
                                    disabled={roleId === '4'}
                                    className="bg-orange-500 py-3  hover:bg-orange-700 text-sm text-white font-bold px-7 rounded ml-2"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </span>
                <div className="bg-white rounded shadow-md">
                    <Paper >
                        <TableContainer>
                            <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                                <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                                    <TableRow>
                                        <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                        <TableCell sx={{ color: 'white', width: '80px' }}>ID</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Name</TableCell>
                                        <TableCell sx={{ color: 'white', width: '50px' }}>Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                        <TableRow key={item.id} hover>
                                            <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>
                                                <span className="flex">
                                                    <IconButton disabled={roleId === '4'} onClick={() => handleItemClick(item)} aria-label="edit">
                                                        <FaRegEdit className="text-blue-700" />
                                                    </IconButton>
                                                    {(roleId === '1' || roleId === '4') && (
                                                        <IconButton disabled={roleId === '4'} onClick={() => {
                                                            dispatch(deleteThunk(item.id));
                                                        }} aria-label="delete">
                                                            <FaTrash className="text-red-600" />
                                                        </IconButton>
                                                    )}
                                                </span>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination Component */}
                        <TablePagination
                            component="div"
                            count={filteredData.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                        />
                    </Paper>
                </div>
            </div>
        </>
    )
}

export default TableView