import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const deleteStockOut = createAsyncThunk(
    "deleteStockOut",
    async (id, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-out/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const deleteReturn = createAsyncThunk(
    "deleteReturn",
    async (id, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/return/history/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const deleteDamage = createAsyncThunk(
    "deleteDamage",
    async (id, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/demage/history/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const superReportDeleteSlice = createSlice({
    name: 'report',
    initialState: {
        deleteStatus: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteStockOut.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteStockOut.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
                state.brand = action.payload;
            })
            .addCase(deleteStockOut.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            })
        builder
            .addCase(deleteReturn.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteReturn.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
                state.brand = action.payload;
            })
            .addCase(deleteReturn.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            })
        builder
            .addCase(deleteDamage.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteDamage.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
                state.brand = action.payload;
            })
            .addCase(deleteDamage.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default superReportDeleteSlice.reducer;
