import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginUser } from '../../redux/Slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/utils/Loader';
import Logo from '../../assests/logoSvg.svg';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const { token, roleId, loading, error } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            let targetPath = '/';

            switch (roleId) {
                case '1': targetPath = '/super-admin'; break;
                case '2': targetPath = '/admin'; break;
                case '3': targetPath = '/$godown'; break;
                case '4': targetPath = '/super-admin'; break;
                default: targetPath = '/'; break;
            }

            navigate(targetPath);
        }
    }, [token, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name) {
            setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.password) newErrors.password = 'Password is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();

        if (Object.keys(validationErrors).length === 0) {
            dispatch(loginUser(formData));
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            {loading === true && (
                <Loader />
            )}
            <div className="flex justify-center items-center h-screen bg-gray-800">
                <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-10 pt-0 pb-16 ">
                    <div className="flex justify-center ">
                        <img
                            src={Logo}
                            alt="Logo"
                            className="h-[8rem] w-[10rem] "
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-5">
                            <label className="block text-sm font-semibold mb-2 text-gray-600" htmlFor="email">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:shadow-md"
                                placeholder="Enter your email"
                                required
                            />
                        </div>

                        <div className="mb-6">
                            <label className="block text-sm font-semibold mb-2 text-gray-600" htmlFor="password">
                                Password
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:shadow-md"
                                placeholder="Enter your password"
                                required
                            />
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-500 transition duration-300 transform hover:scale-105"
                            disabled={loading}
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>

                    {error && <span className="flex mt-6 justify-center text-red-600">{error}</span>}
                </div>
            </div>



        </>
    );
};

export default Login;
