import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getRoles = createAsyncThunk(
    "super_admin/getCategory",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/role`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addUser = createAsyncThunk(
    "addUser",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/role/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateUser = createAsyncThunk(
    "updateUser",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/role/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const deleteRole = createAsyncThunk(
    "deleteRole",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/role/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);


const superAdminUsersSlice = createSlice({
    name: 'users',
    initialState: {
        userData: [],
        getStatus: 'idle',
        addStatus: 'idle',
        updateStatus: 'idle',
        deleteStatus: 'idle',
        error: null,
        users: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRoles.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.userData = action.payload.data;
            })
            .addCase(getRoles.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addUser.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.category = action.payload;
            })
            .addCase(addUser.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateUser.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                state.category = action.payload;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteRole.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteRole.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(deleteRole.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default superAdminUsersSlice.reducer;
