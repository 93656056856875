import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from '../../redux/Slices/SuperAdmin/productSlice';
import { getAdminProduct } from '../../redux/Slices/Admin/adminProductSlice';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Button,
    InputLabel,
    FormControl,
    MenuItem,
    TextField,
    Select,
    IconButton,
    Modal,
    Typography,
    Box,
} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import Topbar from '../../components/Layout/Topbar';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import { AiOutlinePicture } from 'react-icons/ai';
import MessageIcon from '@mui/icons-material/Message';
import { getBrand } from '../../redux/Slices/SuperAdmin/inventory/brandSlice';
import { getSize } from '../../redux/Slices/Measures/sizeSlice';
import { getCategory } from '../../redux/Slices/SuperAdmin/categorySlice';
import { getGodown } from '../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { getFinish } from '../../redux/Slices/SuperAdmin/finishSlice';
import { deleteStockOut } from '../../redux/Slices/SuperAdmin/reportDeleteSlice';
import { FaTrash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/utils/Loader';

const StockoutReport = () => {
    const dispatch = useDispatch();
    const { AllProduct = [], getStatus } = useSelector((state) => state.product);
    const { AdminProducts = [], adminGetStatus } = useSelector((state) => state.adminProduct);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const roleID = localStorage.getItem('roleId')
    const [selectedGodown, setSelectedGodown] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentRemarks, setCurrentRemarks] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedFinish, setSelectedFinish] = useState('');
    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const { brandData = [] } = useSelector(state => state.superAdminBrand);
    const { sizeData = [] } = useSelector(state => state.superAdminSize);
    const { data = [] } = useSelector(state => state.superAdminCategory);
    const { finishData = [] } = useSelector(state => state.finish);
    const { deleteStatus } = useSelector(state => state.reprtDelete);
    const [prevStatus, setPrevStatus] = useState({ delete: '' });

    useEffect(() => {
        if (deleteStatus === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('Deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStatus });
        } else {
            setPrevStatus({ delete: deleteStatus });
        }
    }, [deleteStatus]);

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAdminProduct());
    }, [dispatch, deleteStatus]);

    useEffect(() => {
        dispatch(getBrand());
        dispatch(getSize());
        dispatch(getCategory());
        dispatch(getGodown());
        dispatch(getFinish());
    }, [])

    const handleGodownChange = (event) => {
        setSelectedGodown(event.target.value);
    };

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };
    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleFinishChange = (event) => {
        setSelectedFinish(event.target.value);
    };


    // const godownOptions = Array.from(
    //     new Set(((roleID === '1' || roleID === '4') ? AllProduct : AdminProducts).flatMap((product) =>
    //         product.inventories
    //             .filter((inv) => inv.godowns_name && inv.type === 'stock-in')
    //             .map((inv) => inv.godowns_name.name)
    //     ))
    // );

    const filteredInventories = ((roleID === '1' || roleID === '4') ? AllProduct : AdminProducts).map(product => {
        return {
            ...product,
            inventories: product.inventories.filter(inv =>
                inv.stockout && inv.stockout.length > 0 &&
                (!selectedGodown || inv.godowns_name?.name === selectedGodown) &&
                (!selectedBrand || product.brandname === selectedBrand) &&
                (!selectedCategory || product.categoriename === selectedCategory) &&
                (!selectedFinish || product.finish === selectedFinish) &&
                (!selectedSize || product.size === selectedSize) &&
                // Date filtering logic
                (!fromDate || new Date(inv.stockout[0].product_date) >= new Date(fromDate)) &&
                // Add one day to toDate to include the entire day
                (!toDate || new Date(inv.stockout[0].product_date) < new Date(new Date(toDate).setDate(new Date(toDate).getDate() + 1)))
            )
        };
    }).filter(product => {
        const searchLower = searchQuery.toLowerCase();
        return product.name.toLowerCase().includes(searchLower) ||
            product.sku.toLowerCase().includes(searchLower) ||
            product.subcategoriesname?.toLowerCase().includes(searchLower) ||
            product.inventories.some(inv => {
                return inv.stockout.some(stockout => {
                    return stockout.batch?.toLowerCase().includes(searchLower);
                });
            });
    });

    const displayedRows = filteredInventories
        .flatMap((product) =>
            product.inventories.map((inventory) => ({
                ...inventory,
                product,
                stockout: inventory.stockout.map((stockout) => ({
                    ...stockout,
                    product,
                    inventory,
                })),
            }))
        )
        .flatMap((row) => row.stockout);

    const count = displayedRows.length;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? count : parseInt(value, 10));
        setPage(0);
    };

    const downloadCSV = () => {
        const csvRows = [];
        csvRows.push([(roleID === '1' || roleID === '4') ? 'GODOWN' : '', 'SKU', 'NAME', 'BRAND', 'CATEGORY', 'SUB-CATEGORY', 'BATCH', 'STOCK OUT QTY', 'UOM', 'SIZE', 'COLOR', 'FINISH', 'THICKNESS', 'DATE']);

        filteredInventories
            .flatMap((product) =>
                product.inventories.map((inventory) => ({
                    ...inventory,
                    product,
                    stockout: inventory.stockout.map((stockout) => ({
                        ...stockout,
                        product,
                        inventory,
                    })),
                }))
            )
            .flatMap((row) => row.stockout)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
                csvRows.push([
                    (roleID === '1' || roleID === '4') ? row.inventory.godowns_name?.name : '',
                    row.product.sku,
                    row.product.name,
                    row.product.brandname,
                    row.product.categoriename,
                    row.product.subcategoriesname,
                    row.batch || 'N/A',
                    row.previous_qty,
                    row.product.uom,
                    row.product.size,
                    row.product.color,
                    row.product.finish,
                    row.product.thickness,
                    new Date(row.product_date).toLocaleDateString('en-GB', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }),
                ]);
            });

        const csvString = csvRows.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', 'stock_out_report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleOpenModal = (remarks) => {
        setCurrentRemarks(remarks);
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };
    const isPending = adminGetStatus === 'pending' || getStatus === 'pending' || deleteStatus === 'pending';

    const sortedArray = filteredInventories.flatMap((product) =>
        product.inventories.flatMap((inventory) =>
            inventory.stockout.map((stockout) => ({
                id: product.id,
                deleteId: stockout.id,
                sku: product.sku,
                name: product.name,
                brand: product.brandname,
                category: product.categoriename,
                subCategory: product.subcategoriesname,
                batch: stockout.batch || 'N/A', // Handle cases where batch might be undefined
                size: product.size,
                color: product.color,
                quantity: stockout.previous_qty, // Assuming this is the quantity you want
                uom: product.uom,
                finish: product.finish,
                thickness: product.thickness,
                mrp: stockout.mrp, // Assuming stockout has an MRP
                remarks: stockout.remarks,
                productDate: new Date(stockout.product_date),
                godownName: inventory.godowns_name?.name, // Get the godown name if available
                imageUrl: product.ImageUrl // Include the image URL
            }))
        )
    ).sort((a, b) => b.productDate - a.productDate);
    console.log('test ' + JSON.stringify(sortedArray))

    return (
        <>

            <ToastContainer theme='dark' />

            {(roleID === '1' || roleID === '4') && (
                <Topbar activeTab={'stock out report'} />
            )}
            {isPending && (
                <Loader />
            )}
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div style={{ padding: '20px', background: 'white', margin: '100px auto', maxWidth: '500px', borderRadius: '8px' }}>
                    <Typography id="modal-title" variant="h6">Remarks</Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {currentRemarks || 'No remarks available'}
                    </Typography>
                </div>
            </Modal>

            <div className="p-3 bg-gray-50 shadow-lg rounded-lg">
                <div className="flex justify-between items-center mb-4 bg-gradient-to-r gap-9 from-gray-600 to-gray-600 rounded-t-lg p-5 ">
                    <span className="flex flex-col w-full">
                        {/* top filters */}

                        <Box display="flex" flexDirection="row" paddingTop={1} paddingBottom={2} gap={2}>
                            {roleID === '1' && (
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="godown-select-label" sx={{ color: '#FFFFFF' }}>Godown</InputLabel>
                                    <Select
                                        labelId="godown-select-label"
                                        value={selectedGodown}
                                        onChange={handleGodownChange}
                                        label="Godown"
                                        sx={{
                                            bgcolor: '#90E0',
                                            color: '#FFFFFF',
                                            '& .MuiSelect-icon': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FFFFFF',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiSelect-select': {
                                                color: '#FFFFFF',
                                            },
                                        }}
                                    >
                                        <MenuItem value=""><em >All Godowns</em></MenuItem>
                                        {godownData.map(godown => (
                                            <MenuItem key={godown.id} value={godown.name}>{godown.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="brand-select-label" sx={{ color: '#FFFFFF' }}>Brand</InputLabel>
                                <Select
                                    labelId="brand-select-label"
                                    value={selectedBrand}
                                    onChange={handleBrandChange}
                                    label="Brand"
                                    sx={{
                                        bgcolor: '#90E00',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Brands</em></MenuItem>
                                    {brandData.map(brand => (
                                        <MenuItem key={brand.id} value={brand.name}>{brand.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>


                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="category-select-label" sx={{ color: '#FFFFFF' }}>Category</InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    label="Category"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Categories</em></MenuItem>
                                    {data.map(category => (
                                        <MenuItem key={category.id} value={category.name}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="size-select-label" sx={{ color: '#FFFFFF' }}>size</InputLabel>
                                <Select
                                    labelId="size-select-label"
                                    value={selectedSize}
                                    onChange={handleSizeChange}
                                    label="Size"
                                    sx={{
                                        bgcolor: '#90E00',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Sizes</em></MenuItem>
                                    {sizeData.map(size => (
                                        <MenuItem key={size.id} value={size.name}>{size.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="finish-select-label" sx={{ color: '#FFFFFF' }}>Finish</InputLabel>
                                <Select
                                    labelId="finish-select-label"
                                    value={selectedFinish}
                                    onChange={handleFinishChange}
                                    label="Finish"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root ```javascript': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Finishes</em></MenuItem>
                                    {finishData.map(finish => (
                                        <MenuItem key={finish.id} value={finish.name}>{finish.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <span className='flex items-center justify-between -mt-4"' >
                            <input
                                type="text"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className='border border-blue-600 rounded-lg w-[20rem] h-11 py-2 px-2 '

                                placeholder="Search"
                            />
                            {/* {(roleID === '1' || roleID === '4') && (
                            <select
                                value={selectedGodown}
                                onChange={(e) => setSelectedGodown(e.target.value)}
                                className='border border-blue-600 rounded-lg w-[12rem] h-11 py-2 px-2 '
                            >
                                <option value="">All Godowns</option>
                                {godownOptions.map((godown, index) => (
                                    <option key={index} value={godown}>{godown}</option>
                                ))}
                            </select>
                        )} */}
                            <div className="flex items-center">
                                <input
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    className='border border-blue-600 rounded-lg w-[9rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400'
                                />

                                <MultipleStopIcon className="text-white mx-2" />

                                <input
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    className='border border-blue-600 rounded-lg w-[9rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400'
                                />
                            </div>

                            <Button
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                onClick={downloadCSV}
                                className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                            >
                                Download
                            </Button>
                        </span>
                    </span>
                </div>
                <TableContainer component={Paper} className="rounded-lg mt-[-2rem] shadow-md overflow-hidden">
                    <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                            <TableRow className="">
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">SL No.</TableCell>
                                {(roleID === '1' || roleID === '4') && (<TableCell sx={{ color: 'white', width: '200px' }} className="text-center text-xs font-bold py-3">Godown</TableCell>)}
                                <TableCell sx={{ color: 'white', width: '140px' }} className="text-center text-xs font-bold py-3">SKU</TableCell>
                                <TableCell sx={{ color: 'white', width: '240px' }} className="text-center text-xs font-bold py-3">Name</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }} className="text-center text-xs font-bold py-3">Brand</TableCell>
                                <TableCell sx={{ color: 'white', width: '160px' }} className="text-center text-xs font-bold py-3">Category</TableCell>
                                <TableCell sx={{ color: 'white', width: '160px' }} className="text-center text-xs font-bold py-3">Sub-Category</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }} className="text-center text-xs font-bold py-3">Batch</TableCell>
                                <TableCell sx={{ color: 'white', width: '120px' }} className="text-center text-xs font-bold py-3">Size</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }} className="text-center text-xs font-bold py-3">Color</TableCell>
                                <TableCell sx={{ color: 'white', width: '80px' }} className="text-center text-xs font-bold py-3">QTY</TableCell>
                                <TableCell sx={{ color: 'white', width: '80px' }} className="text-center text-xs font-bold py-3">UOM</TableCell>
                                <TableCell sx={{ color: 'white', width: '160px' }} className="text-center text-xs font-bold py-3">Finish</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Thickness</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">MRP</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Remarks</TableCell>
                                <TableCell sx={{ color: 'white', width: '120px' }} className="text-center text-xs font-bold py-3">Date</TableCell>
                                <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Image</TableCell>
                                {(roleID === '1') && (

                                    <TableCell sx={{ color: 'white', width: '100px' }} className="text-center text-xs font-bold py-3">Action</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedArray
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow key={row.id} className="hover:bg-gray-100 transition-all text-sm">
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        {(roleID === '1' || roleID === '4') && (
                                            <TableCell className="text-center py-1">{row.godownName}</TableCell>
                                        )}
                                        <TableCell className="text-center py-1">{row.sku}</TableCell>
                                        <TableCell className="text-center py-1">{row.name}</TableCell>
                                        <TableCell className="text-center py-1">{row.brand}</TableCell>
                                        <TableCell className="text-center py-1">{row.category}</TableCell>
                                        <TableCell className="text-center py-1">{row.subCategory}</TableCell>
                                        <TableCell className="text-center py-1">{row.batch}</TableCell>
                                        <TableCell className="text-center py-1">{row.size}</TableCell>
                                        <TableCell className="text-center py-1">{row.color}</TableCell>
                                        <TableCell className="text-center py-1">{row.quantity}</TableCell>
                                        <TableCell className="text-center py-1">{row.uom}</TableCell>
                                        <TableCell className="text-center py-1">{row.finish}</TableCell>
                                        <TableCell className="text-center py-1">{row.thickness}</TableCell>
                                        <TableCell className="text-center py-1">{row.mrp}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleOpenModal(row.remarks)}>
                                                <MessageIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className="text-center py-1">
                                            {new Date(row.productDate).toLocaleDateString('en-GB', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            })}
                                        </TableCell>
                                        <TableCell>
                                            {row.imageUrl && (
                                                <div className="cursor-pointer h-1 w-1 items-center" onClick={() => setShowImage(row.imageUrl)}>
                                                    <AiOutlinePicture size={24} />

                                                    {showImage === row.imageUrl && (
                                                        // Modal Background
                                                        <div
                                                            className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-10"
                                                            onClick={() => setShowImage(false)}
                                                        >
                                                            {/* Modal Content */}
                                                            <div
                                                                className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                                                style={{ width: '80%', maxWidth: '500px' }}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                {/* Image */}
                                                                <div className="flex justify-center">
                                                                    <img
                                                                        src={row.imageUrl}
                                                                        alt="Product"
                                                                        className="w-full h-full object-cover rounded-lg"
                                                                        style={{ maxHeight: '70vh' }}
                                                                    />
                                                                </div>

                                                                {/* Action Buttons */}
                                                                <div className="mt-4 flex justify-center space-x-4">
                                                                    <button
                                                                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                        onClick={() => setShowImage(false)}
                                                                    >
                                                                        Close
                                                                    </button>
                                                                    <button
                                                                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                        onClick={() => window.open(row.imageUrl, '_blank')}
                                                                    >
                                                                        Open in New Tab
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <span className="flex">
                                                {(roleID === '1') && (
                                                    <IconButton onClick={() => {
                                                        dispatch(deleteStockOut(row.deleteId));
                                                    }} aria-label="delete">
                                                        <FaTrash className="text-red-600" />
                                                    </IconButton>
                                                )}
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                    className="mt-4"
                />
            </div>
        </>
    );
};

export default StockoutReport;
