import { CircularProgress } from '@mui/material';

function Loader() {
    return (


        <div className="fixed z-50 inset-0 flex items-center justify-center bg-black bg-opacity-80">
            <div className="grid grid-cols-3 gap-4">
                <div className="w-10 h-10 bg-gradient-to-r from-blue-500 to-purple-600 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
                <div className="w-10 h-10 bg-gradient-to-r from-red-500 to-orange-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
                <div className="w-10 h-10 bg-gradient-to-r from-yellow-500 to-green-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>

                <div className="w-10 h-10 bg-gradient-to-r from-teal-500 to-blue-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
                <div className="w-10 h-10 bg-gradient-to-r from-purple-600 to-pink-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
                <div className="w-10 h-10 bg-gradient-to-r from-pink-500 to-red-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>

                <div className="w-10 h-10 bg-gradient-to-r from-indigo-500 to-blue-600 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
                <div className="w-10 h-10 bg-gradient-to-r from-green-500 to-yellow-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
                <div className="w-10 h-10 bg-gradient-to-r from-orange-500 to-red-500 animate-pulse rounded-lg shadow-lg transform hover:scale-125 transition-transform duration-300 ease-in-out"></div>
            </div>
        </div>




    );
}
export default Loader