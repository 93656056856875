import React, { useEffect, useState } from 'react';
import { getRoles, addUser, updateUser, deleteRole } from '../../../redux/Slices/SuperAdmin/rolesSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableView from '../../../components/utils/TableView';
import Topbar from '../../../components/Layout/Topbar';

const Users = () => {

    const { userData = [], updateStatus, getStatus, addStatus, deleteStatus } = useSelector(state => state.superAdminRoles);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch, updateStatus, addStatus]);

    return (
        <>
            <div className='p-5'>
                <Topbar activeTab={'roles'} />
                <TableView
                    data={userData}
                    deleteThunk={deleteRole}
                    addThunk={addUser}
                    updateThunk={updateUser}
                />
            </div>
        </>
    );
};

export default Users;