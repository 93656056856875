import React, { useState, useEffect } from 'react';
import { apiUrl } from '../../../redux/apiConfig';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Topbar from '../../../components/Layout/Topbar';

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchLogs();
    }, []);

    useEffect(() => {

        const filteredData = logs.filter(log =>
            log.username.toLowerCase().includes(search.toLowerCase()) ||
            log.empCode.toLowerCase().includes(search.toLowerCase()) ||
            log.roleName.toLowerCase().includes(search.toLowerCase()) ||
            log.godownsName.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredLogs(filteredData);
        setTotalCount(filteredData.length);
        setPage(0);
    }, [search, logs]);

    const fetchLogs = async () => {
        try {
            const response = await fetch(
                `${apiUrl}super-admin/user/log-info?page=${page + 1}&limit=${rowsPerPage}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            const data = await response.json();
            setLogs(data.data);
            setTotalCount(data.total);
        } catch (error) {
            console.error(error);
        }
    };

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? totalCount : parseInt(value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    return (
        <div className='p-3'>
            <Topbar activeTab={'logs'} />
            <span className='flex p-6 items-center justify-between bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg'>
                <input
                    type="text"
                    className="rounded w-[20rem] py-2   p-5 border border-gray-400"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearchChange}
                />
            </span>
            <Paper >
                <TableContainer>
                    <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                        <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                            <TableRow>
                                <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                <TableCell sx={{ color: 'white', width: '140px' }}>EMP Code</TableCell>
                                <TableCell sx={{ color: 'white', width: '200px' }}>Username</TableCell>
                                <TableCell sx={{ color: 'white', width: '120px' }}>Role</TableCell>
                                <TableCell sx={{ color: 'white', width: '140px' }}>Godown</TableCell>
                                <TableCell sx={{ color: 'white', width: '150px' }}>IP</TableCell>
                                <TableCell sx={{ color: 'white', width: '200px' }}>Logged At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredLogs
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((log, index) => (
                                    <TableRow key={log.id}>
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell>{log.empCode}</TableCell>
                                        <TableCell>{log.username}</TableCell>
                                        <TableCell>{log.roleName}</TableCell>
                                        <TableCell>{log.godownsName}</TableCell>
                                        <TableCell>{log.ip}</TableCell>
                                        <TableCell>
                                            {new Date(log.created_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) + '   ' + new Date(log.created_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                                        </TableCell>                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    count={totalCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100, 'all']}

                />
            </Paper>
        </div>
    );
};

export default Logs;
