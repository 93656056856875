import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDevice, updateDevice } from '../../../redux/Slices/SuperAdmin/deviceSlice';
import Loader from '../../../components/utils/Loader';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import Topbar from '../../../components/Layout/Topbar';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeviceLog = () => {
    const dispatch = useDispatch();
    const { deviceData = [], getStatus, updateStatus, error } = useSelector(state => state.device);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredUsers, setFilteredUsers] = useState(deviceData);
    const [selectedUser, setSelectedUser] = useState({});
    const [prevStatus, setPrevStatus] = useState({ add: '', update: '' });
    const { roleId } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getDevice());
    }, [dispatch, updateStatus])

    useEffect(() => {
        if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Status changed successfully!');
            setPrevStatus({ ...prevStatus, update: updateStatus });
        } else {
            setPrevStatus({ update: updateStatus });
        }
    }, [updateStatus]);

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredUsers(
            deviceData.filter(user =>

                String(user.name).toLowerCase().includes(lowerCaseQuery) ||
                String(user.empCode).toLowerCase().includes(lowerCaseQuery) ||
                String(user.imei).toLowerCase().includes(lowerCaseQuery) ||
                String(user.version).toLowerCase().includes(lowerCaseQuery) ||
                String(user.app_version).toLowerCase().includes(lowerCaseQuery) ||
                (lowerCaseQuery == 'active' && user.device_active == 1) ||
                (lowerCaseQuery == 'deactive' && user.device_active == 0) ||
                String(user.user_id).toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery, deviceData]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isPending = getStatus === 'pending';

    const handleToggleDevice = (deviceId, isActive) => {
        dispatch(updateDevice({ id: deviceId, device_active: isActive }));
    };


    return (
        <>
            <ToastContainer theme='dark' />
            {isPending && (
                <Loader />
            )}
            {/* Topbar component */}
            <Topbar activeTab={'Devices'} />
            <div className="p-3 relative overflow-x-hidden">

                <span className='flex items-center justify-between bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg'>
                    <div className="mb-4 m-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-2 px-4  border border-gray-400"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </span>
                <Paper >
                    <TableContainer>
                        <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                            <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                    <TableCell sx={{ color: 'white', width: '140px' }}>EMP Code</TableCell>
                                    <TableCell sx={{ color: 'white', width: '200px' }}>Name</TableCell>
                                    <TableCell sx={{ color: 'white', width: '200px' }}>Email</TableCell>
                                    <TableCell sx={{ color: 'white', width: '150px' }}>Model</TableCell>
                                    <TableCell sx={{ color: 'white', width: '150px' }}>IMEI</TableCell>
                                    <TableCell sx={{ color: 'white', width: '100px' }}>Mobile Version</TableCell>
                                    <TableCell sx={{ color: 'white', width: '100px' }}>App Version</TableCell>
                                    <TableCell sx={{ color: 'white', width: '130px' }}>Status</TableCell>
                                    <TableCell sx={{ color: 'white', width: '200px' }}>At</TableCell>
                                    <TableCell sx={{ color: 'white', width: '150px' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <TableRow key={item.id} hover>
                                        <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                        <TableCell>{item.empCode}</TableCell>
                                        <TableCell>{item.userName}</TableCell>
                                        <TableCell>{item.userEmail}</TableCell>
                                        <TableCell>{item.devicename}</TableCell>
                                        <TableCell>{item.imei}</TableCell>
                                        <TableCell>{item.version}</TableCell>
                                        <TableCell>{item.app_version}</TableCell>
                                        <TableCell>{item.device_active === '1' ? 'Active' : 'Deactive'}</TableCell>
                                        <TableCell>
                                            {new Date(item.updated_at).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) + '   ' + new Date(item.updated_at).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                disabled={roleId === '4'}
                                                onClick={() => handleToggleDevice(item.id, item.device_active === '1' ? '0' : '1')}
                                            >
                                                {item.device_active === '1' ? (
                                                    <ToggleOnIcon className="text-green-500" style={{ fontSize: 40 }} />
                                                ) : (
                                                    <ToggleOffIcon className="text-red-500 " style={{ fontSize: 40 }} />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination Component */}
                    <TablePagination
                        component="div"
                        count={filteredUsers.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>

        </>
    )
}

export default DeviceLog
