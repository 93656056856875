import React, { useEffect, useState } from 'react'
import { getUom, addUom, updateUom, deleteUom } from '../../redux/Slices/Measures/uomSlice';
import { getSize, addSize, updateSize, deleteSize } from '../../redux/Slices/Measures/sizeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash, FaRegEdit } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Topbar from '../../components/Layout/Topbar';

const Measures = () => {

    const dispatch = useDispatch();
    const { uomData = [], updateStatus, getStatus, addStatus, deleteStatus } = useSelector(state => state.superAdminUom);
    const { sizeData = [], updateStat, getStat, addStat, deleteStat } = useSelector(state => state.superAdminSize);
    const [uomName, setUomName] = useState('');
    const [sizeName, setSizeName] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [updateItemName, setUpdateItemName] = useState('');
    const [type, setType] = useState('');
    const { roleId } = useSelector((state) => state.auth);
    const [prevStatus, setPrevStatus] = useState({ add: '', update: '', delete: '' });

    useEffect(() => {
        dispatch(getUom());
        dispatch(getSize());
    }, [dispatch, addStat, addStatus, deleteStat, deleteStatus, updateStat, updateStatus])

    useEffect(() => {
        if (addStatus === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('Added successfully!');
            setPrevStatus({ ...prevStatus, add: addStatus });
        } else if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Updated successfully!');
            setPrevStatus({ ...prevStatus, update: updateStatus });
        } else if (deleteStatus === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('Deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStatus });
        } else {
            setPrevStatus({ add: addStatus, update: updateStatus, delete: deleteStatus });
        }
    }, [addStatus, updateStatus, deleteStatus]);
    useEffect(() => {
        if (addStat === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('Added successfully!');
            setPrevStatus({ ...prevStatus, add: addStat });
        } else if (updateStat === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Updated successfully!');
            setPrevStatus({ ...prevStatus, update: updateStat });
        } else if (deleteStat === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('Deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStat });
        } else {
            setPrevStatus({ add: addStat, update: updateStat, delete: deleteStat });
        }
    }, [addStat, updateStat, deleteStat]);


    useEffect(() => {
        if (updateStat === 'succeeded' || updateStatus === 'succeeded') {
            setUpdateForm(false)
        }
    }, [updateStat, updateStatus])

    const handleAddUom = (e) => {
        e.preventDefault();
        dispatch(addUom({ name: uomName }));
        setUomName('');

    };
    const handleAddSize = (e) => {
        e.preventDefault();
        dispatch(addSize({ name: sizeName }));
        setSizeName('');

    };

    const handleItemClick = (category, type) => {
        setSelectedItem(category);
        setUpdateForm(true);
        setType(type);
    };

    const handleUpdateCategory = (e) => {
        e.preventDefault();
        if (type == 'uom') {
            dispatch(updateUom({ id: selectedItem.id, name: updateItemName }));
        } else {
            dispatch(updateSize({ id: selectedItem.id, name: updateItemName }));
        }
        setUpdateItemName('');
        setType('');

    };
    console.log('add status ' + addStatus)
    console.log('add stat ' + addStat)
    return (
        <div className='m-3'>
            {(roleId === '1' || roleId === '4') && (

                <Topbar activeTab={'Measures'} />
            )}

            <ToastContainer theme='dark' />
            {updateForm && (

                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <div className="flex justify-between mb-4">
                            <h2 className="font-semibold text-lg">Update Item</h2>
                            <button
                                className="bg-gray-700 rounded-full p-1 hover:text-gray-300 text-white"
                                onClick={() => setUpdateForm(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="mb-4">
                            <p className="text-gray-600">Selected Item:</p>
                            <p className="text-lg">{selectedItem.id} - {selectedItem.name}</p>
                        </div>

                        <form onSubmit={handleUpdateCategory}>
                            <div className="flex items-center mb-4 gap-4">
                                <input
                                    type="text"
                                    value={updateItemName}
                                    onChange={(e) => setUpdateItemName(e.target.value)}
                                    placeholder="Enter updated entry"
                                    className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                                />
                                <button
                                    type="submit"
                                    className="bg-orange-500 py-3 hover:bg-orange-700 text-sm text-white font-bold px-4 rounded ml-2"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <span className='flex items-center justify-between bg-gradient-to-r from-gray-600 to-gray-700 rounded-t-lg'>
                {/* <div className="mb-4 m-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-2 px-4  border border-gray-400"
                            placeholder="Search by by any field"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div> */}
                <div className="flex justify-end m-5 mb-4 gap-5">
                    <form onSubmit={handleAddUom}>
                        <div className="flex items-center mb-4">
                            <input
                                type="text"
                                value={uomName}
                                onChange={(e) => setUomName(e.target.value)}
                                placeholder="Enter UOM"
                                className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                            />
                            <button
                                type="submit"
                                className="bg-orange-500 py-3  hover:bg-orange-700 text-sm text-white font-bold px-7 rounded ml-2"
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </div>
                <div className="flex justify-end m-5 mb-4 gap-5">
                    <form onSubmit={handleAddSize}>
                        <div className="flex items-center mb-4">
                            <input
                                type="text"
                                value={sizeName}
                                onChange={(e) => setSizeName(e.target.value)}
                                placeholder="Enter size"
                                className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                            />
                            <button
                                type="submit"
                                className="bg-orange-500 py-3  hover:bg-orange-700 text-sm text-white font-bold px-7 rounded ml-2"
                            >
                                Add
                            </button>
                        </div>
                    </form>
                </div>
            </span>

            <div className="flex  gap-5 bg-white rounded  shadow-md">
                <table className="w-full border-separate border-spacing-0">
                    <thead>
                        <tr className="bg-gradient-to-r from-gray-600 to-gray-700">
                            <th className="px-6 py-3 leading-4 font-medium text-left text-gray-100">
                                SL No.
                            </th>
                            <th className="px-6 py-3  leading-4 font-medium text-left text-gray-100">
                                UOM
                            </th>
                            <th className="px-6 py-3 leading-4 font-medium text-left text-gray-100">
                                Action
                            </th>
                        </tr>
                    </thead>
                    {!uomData ? (
                        <h1 className='font-bold'>No data found</h1>
                    ) : (
                        <tbody>
                            {uomData.map((item, index) => (
                                <tr key={item.id} className="bg-white hover:bg-gray-50">

                                    <td className="px-6 py-4 text-sm leading-5 text-gray-900">
                                        {index + 1}
                                    </td>
                                    <td className="px-6 py-4 text-sm leading-5 text-gray-900">
                                        {item.name}
                                    </td>
                                    <td className="px-6 py-4 text-sm leading-5 text-gray-900">
                                        <button
                                            onClick={() => dispatch(deleteUom(item.id))}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
                                        >
                                            <FaTrash />
                                        </button>
                                        <button className="bg-blue-500 ml-3 hover:bg-blue-700 text-white py-2 px-2 rounded hover:scale-110 transition duration-300 ease-in-out"
                                            onClick={() => handleItemClick(item, 'uom')}
                                        >
                                            <FaRegEdit />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
                <table className="w-full border-separate border-spacing-0">
                    <thead>
                        <tr className="bg-gradient-to-r from-gray-600 to-gray-700">
                            <th className="px-6 py-3 leading-4 font-medium text-left text-gray-100">
                                SL No.
                            </th>
                            <th className="px-6 py-3  leading-4 font-medium text-left text-gray-100">
                                Size
                            </th>
                            <th className="px-6 py-3 leading-4 font-medium text-left text-gray-100">
                                Action
                            </th>
                        </tr>
                    </thead>
                    {!sizeData ? (
                        <h1 className='font-bold'>No data found</h1>
                    ) : (
                        <tbody>
                            {sizeData.map((item, index) => (
                                <tr key={item.id} className="bg-white hover:bg-gray-50">

                                    <td className="px-6 py-4 text-sm leading-5 text-gray-900">
                                        {index + 1}
                                    </td>
                                    <td className="px-6 py-4 text-sm leading-5 text-gray-900">
                                        {item.name}
                                    </td>
                                    <td className="px-6 py-4 text-sm leading-5 text-gray-900">
                                        <button
                                            disabled={(roleId === '4' || roleId === '2')}
                                            onClick={() => dispatch(deleteSize(item.id))}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-2 rounded"
                                        >
                                            <FaTrash />
                                        </button>
                                        <button className="bg-blue-500 ml-3 hover:bg-blue-700 text-white py-2 px-2 rounded hover:scale-110 transition duration-300 ease-in-out"
                                            disabled={(roleId === '4' || roleId === '2')}
                                            onClick={() => handleItemClick(item, 'size')}
                                        >
                                            <FaRegEdit />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    )
}

export default Measures