import React from 'react'
import Measures from '../../Products/Measures'
import AdminTopbar from '../Layout/AdminTopbar'

const Measurement = () => {
    return (
        <>
            <AdminTopbar activeTab={'measures'} />
            <Measures />
        </>
    )
}

export default Measurement