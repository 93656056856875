import { useDispatch, useSelector } from "react-redux";
import { getGodownProduct } from "../../redux/Slices/Godown/productSlice";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import Loader from "../../components/utils/Loader";
import { useEffect, useState } from "react";

const GodownProduct = () => {

    const dispatch = useDispatch();
    const { AllData, getStatus, error } = useSelector((state) => state.godownProduct);
    const [filteredProduct, setFilteredProduct] = useState(AllData);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        dispatch(getGodownProduct());
    }, [dispatch]);

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredProduct(
            AllData.filter(product =>
                String(product.name).toLowerCase().includes(lowerCaseQuery) ||
                String(product.id).toLowerCase().includes(lowerCaseQuery) ||
                String(product.brand).toLowerCase().includes(lowerCaseQuery) ||
                String(product.sku).toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery, AllData]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    return (
        <>
            {getStatus === 'pending' && (
                <Loader />
            )}

            <div className="p-5 relative ml-6   overflow-x-hidden">
                <span className="flex items-center justify-between bg-gradient-to-r pb-14 from-gray-600 to-gray-600 rounded-t-lg">
                    <div className="mb-4 m-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-2 px-4 border border-gray-400"
                            placeholder="Search by any field"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </span>

                <div className="overflow-x-auto mt-[-60px]">
                    <Paper >
                        <TableContainer>
                            <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                                <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                                    <TableRow>
                                        <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Godown</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>SKU</TableCell>
                                        <TableCell sx={{ color: 'white', width: '200px' }}>Name</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Brand</TableCell>
                                        <TableCell sx={{ color: 'white', width: '200px' }}>Category</TableCell>
                                        <TableCell sx={{ color: 'white', width: '200px' }}>SubCategory</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Batch</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Size</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>UOM</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Color</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Quantity</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Finish</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>MRP</TableCell>
                                        <TableCell sx={{ color: 'white', width: '200px' }}>Added On</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Image</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredProduct.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                        <TableRow key={item.id} hover>
                                            <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                            <TableCell>{item.godownsname}</TableCell>
                                            <TableCell>{item.sku}</TableCell>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>{item.brand}</TableCell>
                                            <TableCell>{item.categoriename}</TableCell>
                                            <TableCell>{item.subcategoriesname}</TableCell>
                                            <TableCell>{item.batch}</TableCell>
                                            <TableCell>{item.size}</TableCell>
                                            <TableCell>{item.uom}</TableCell>
                                            <TableCell>{item.color}</TableCell>
                                            <TableCell>{item.qty}</TableCell>
                                            <TableCell>{item.finish}</TableCell>
                                            <TableCell>{item.mrp}</TableCell>
                                            <TableCell>{item.product_in_date}</TableCell>
                                            <TableCell>{/* Add image here if available */}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            component="div"
                            count={filteredProduct.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>


        </>
    )
}

export default GodownProduct;