import React, { useEffect, useState } from 'react';
import { getUsers, userUpdate } from '../../../redux/Slices/SuperAdmin/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../../redux/Slices/SuperAdmin/rolesSlice';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const UpdateUser = ({ user }) => {
    const [firstName, setFirstName] = useState(user.name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [role, setRole] = useState(user.role_id);
    const [isActive, setIsActive] = useState(user.is_active);
    console.log(isActive == 1)
    const [password, setPassword] = useState(user.use_password);
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const { AllUsers } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getUsers());
    }, [dispatch]);

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(userUpdate({
            name: firstName,
            last_name: lastName,
            email,
            phone,
            password,
            role,
            id: user.id,
            is_active: isActive
        }));
    };

    return (
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md mt-6">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="first-name"
                        >
                            First Name
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="first-name"
                            type="text"
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            placeholder="Enter first name"
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="last-name"
                        >
                            Last Name
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="last-name"
                            type="text"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            placeholder="Enter last name"
                        />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="email"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Enter email"
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="phone"
                        >
                            Phone
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="phone"
                            type="tel"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            placeholder="Enter phone number"
                        />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="password">
                            Password
                        </label>
                        <div className="relative">
                            <input
                                className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                                id="password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                placeholder="Enter password"
                            />
                            {showPassword ? (
                                <AiOutlineEyeInvisible
                                    className="absolute h-5 w-5 right-3 top-4 cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            ) : (
                                <AiOutlineEye
                                    className="absolute h-5 w-5 right-3 top-4 cursor-pointer"
                                    onClick={() => setShowPassword(!showPassword)}
                                />
                            )}
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="role"
                        >
                            Role
                        </label>
                        <select
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="role"
                            defaultValue={role}
                            onChange={(event) => setRole(event.target.value)}
                        >
                            <option value="">Select Role</option>
                            <option value="1">Super Admin </option>
                            <option value="2">Godown Admin</option>
                            <option value="3">App User</option>
                            <option value="4">Sub Admin</option>
                        </select>
                    </div>

                </div>
                <div className="flex flex-wrap -mx-3 mb-6">

                    <div className="w-full  px-3">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="isActive"
                        >
                            Activation
                        </label>
                        <select
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="isActive"
                            value={isActive}
                            onChange={(event) => setIsActive(event.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="1">Activate</option>
                            <option value="0">Deactivate</option>
                        </select>
                    </div>
                </div>

                <div className="text-center">
                    <button
                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg shadow-md focus:outline-none transition ease-in-out duration-300"
                        type="submit"
                    >
                        Update
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdateUser;