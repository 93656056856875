import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getProduct = createAsyncThunk(
    "super_admin/getProduct",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addProduct = createAsyncThunk(
    "super_admin/addProduct",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            formData.append('name', productData.name);
            formData.append('sku', productData.sku);
            formData.append('size', productData.size);
            formData.append('uom', productData.uom);
            formData.append('product_in_date', productData.product_in_date);
            formData.append('color', productData.color);
            formData.append('brand', productData.brand);
            formData.append('category_id', productData.category_id);
            formData.append('sub_category', productData.sub_category);
            formData.append('thickness', productData.thickness);
            formData.append('finish', productData.finish);

            if (productData.image && productData.image instanceof File) {
                formData.append('image', productData.image);
            }

            const response = await fetch(`${apiUrl}super-admin/product/add`, {
                method: 'POST',
                headers: {

                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the product.' });
        }
    }
);

export const productUpdate = createAsyncThunk(
    "super_admin/productUpdate",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            formData.append('id', productData.id);
            formData.append('name', productData.name);
            formData.append('brand', productData.brand);
            formData.append('category_id', productData.category_id);
            formData.append('sub_category', productData.sub_category);
            formData.append('size', productData.size);
            formData.append('uom', productData.uom);
            formData.append('product_in_date', productData.product_in_date);
            formData.append('color', productData.color);
            formData.append('description', productData.desc);
            formData.append('thickness', productData.thickness);
            formData.append('finish', productData.finish);
            formData.append('dis_continue', productData.dis_continue);

            if (productData.image && productData.image instanceof File) {
                formData.append('image', productData.image);
            }

            const response = await fetch(`${apiUrl}super-admin/product/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while updating the product.' });
        }
    }
);

export const productDelete = createAsyncThunk(
    "productdelete",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    addStatus: 'idle',
    AllProduct: [],
    getStatus: 'idle',
    updateStatus: 'idle',
    deleteStatus: 'idle',
    error: null,
    product: null,
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProduct.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.AllProduct = action.payload.data;
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addProduct.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.product = action.payload;
            })
            .addCase(addProduct.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productUpdate.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(productUpdate.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(productUpdate.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(productDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(productDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default productSlice.reducer;
