import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const releaseHold = createAsyncThunk(
    "releaseHold",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/hold-release`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const releaseHoldSuper = createAsyncThunk(
    "releaseHoldSuper",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/hold-release`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const adminHoldSlice = createSlice({
    name: 'device',
    initialState: {
        holdStatus: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(releaseHold.pending, (state) => {
                state.holdStatus = 'pending';
            })
            .addCase(releaseHold.fulfilled, (state, action) => {
                state.holdStatus = 'succeeded';
            })
            .addCase(releaseHold.rejected, (state, action) => {
                state.holdStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(releaseHoldSuper.pending, (state) => {
                state.holdStatus = 'pending';
            })
            .addCase(releaseHoldSuper.fulfilled, (state, action) => {
                state.holdStatus = 'succeeded';
            })
            .addCase(releaseHoldSuper.rejected, (state, action) => {
                state.holdStatus = 'failed';
                state.error = action.payload;
            });

    }
});

export default adminHoldSlice.reducer;
