import { productUpdate } from "../../redux/Slices/SuperAdmin/productSlice"
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBrand } from '../../redux/Slices/SuperAdmin/inventory/brandSlice';
import { getGodown } from '../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { getCategory } from '../../redux/Slices/SuperAdmin/categorySlice';
import { getUom } from '../../redux/Slices/Measures/uomSlice';
import { getSize } from '../../redux/Slices/Measures/sizeSlice';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { productAdminUpdate } from "../../redux/Slices/Admin/adminProductSlice";
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";
import { Button, Modal, TextField } from "@mui/material";


const UpdateProduct = ({ product }) => {
    const [sku, setSku] = useState(product.sku);
    const [name, setName] = useState(product.name);
    const [category_id, setCategory_id] = useState({ id: product.category_id, name: product.categoriename });
    const [sub_category, setSubCategories] = useState({ id: product.sub_category, name: product.subcategoriesname });
    const [brand, setBrand] = useState({ id: product.brand, name: product.brandname });
    const [size, setSize] = useState(product.size);
    const [uom, setUom] = useState(product.uom);
    const [color, setColor] = useState(product.color);
    const [desc, setDesc] = useState(product.description);
    const [thickness, setThickness] = useState(product.thickness);
    const [finish, setFinish] = useState(product.finish);
    const [image, setImage] = useState(null);
    const [isActive, setIsActive] = useState(product.is_active);
    const { finishData = [] } = useSelector(state => state.finish);
    const [productInDate, setProductInDate] = useState(new Date().toISOString().split('T')[0]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roleId } = useSelector((state) => state.auth);
    const { AdminProducts = [], adminUpdateStatus } = useSelector(state => state.adminProduct);
    const { updateStatus, addStatus, error } = useSelector((state) => state.product);
    const { brandData = [] } = useSelector(state => state.superAdminBrand);
    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const { data = [] } = useSelector(state => state.superAdminCategory);
    const { uomData = [] } = useSelector(state => state.superAdminUom);
    const { sizeData = [] } = useSelector(state => state.superAdminSize);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { AllProduct = [] } = useSelector(state => state.product);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleSave = () => {
        // Save the description logic here
        console.log('Description saved:', desc);
        closeModal(); // Close the modal after saving
    };

    useEffect(() => {
        dispatch(getFinish());
        dispatch(getBrand());
        dispatch(getGodown());
        dispatch(getCategory());
        dispatch(getUom());
        dispatch(getSize());

    }, [dispatch]);

    const handleSameSubmit = (event) => {
        event.preventDefault();

        if (roleId === '1') {
            const productData = {
                id: product.id,
                name,
                brand: brand.id,
                category_id: category_id.id,
                sub_category: sub_category.id,
                product_in_date: productInDate,
                size,
                uom,
                finish,
                thickness,
                color,
                desc,
                dis_continue: isActive,
                image
            };

            const updateProducts = [productData];
            dispatch(productUpdate(productData));
        } else {
            const productData = {
                id: product.id,
                name,
                brand: brand.id,
                category_id: category_id.id,
                sub_category: sub_category.id,
                product_in_date: productInDate,
                size,
                uom,
                color,
                desc,
                thickness,
                finish,
                dis_continue: isActive,
                image
            }
            const updateProducts = [productData];
            dispatch(productAdminUpdate(productData));
        }
    };

    const subcategoryOptions = data
        .filter((category) => category.id == category_id.id)
        .map((category) => category.subcategories.map((sub) => ({ id: sub.id, name: sub.name })));

    return (
        <>
            <div className="max-w-3xl h-[33rem] mx-auto bg-white p-3 rounded-lg pt-10  ">
                <form onSubmit={handleSameSubmit}>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="sku"
                            >
                                SKU
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="sku"
                                type="text"
                                value={sku}
                                // onChange={(event) => setSku(event.target.value)}
                                disabled={true}
                                placeholder="Enter SKU"
                                readOnly
                            />
                        </div>
                        <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="productName"
                                >
                                    Product Name
                                </label>
                            </span>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="productName"
                                type="text"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                placeholder="Enter product name"
                            />
                        </div>
                        <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="brand"
                                >
                                    Brand
                                </label>
                            </span>
                            <Select
                                id="brand"
                                value={brand ? { value: brand.id, label: brand.name } : null}

                                onChange={(option) => {
                                    if (option) {
                                        setBrand({ id: option.value, name: option.label });
                                    } else {
                                        setBrand(null);
                                    }
                                }}
                                options={brandData.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="category"
                            >
                                Category
                            </label>
                            <Select
                                id="category"
                                value={category_id ? { value: category_id.id, label: category_id.name } : null}
                                onChange={(option) => {
                                    if (option) {
                                        // Set both the selected category id and name
                                        setCategory_id({ id: option.value, name: option.label });
                                    } else {
                                        setCategory_id(null); // Handle case for no option selected
                                    }
                                }}
                                options={data.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="category"
                            >
                                Sub Category
                            </label>
                            <Select
                                id="sub_category"
                                value={sub_category ? { value: sub_category.id, label: sub_category.name } : null}
                                onChange={(option) => {
                                    if (option) {
                                        // Set both the selected sub-category id and name
                                        setSubCategories({ id: option.value, name: option.label });
                                    } else {
                                        setSubCategories(null); // Handle case when no option is selected
                                    }
                                }}
                                options={subcategoryOptions[0]?.map((opt) => ({
                                    value: opt.id,
                                    label: opt.name,
                                }))}
                            />


                        </div>

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="size"
                            >
                                Size
                            </label>
                            <Select
                                id="size"
                                value={size ? { value: size, label: size } : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setSize(option.value);
                                    } else {
                                        setSize(option);
                                    }
                                }}
                                options={sizeData.map((opt) => ({
                                    value: opt.name,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6">

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="uom"
                            >
                                UOM
                            </label>
                            <Select
                                id="uom"
                                value={uom ? { value: uom, label: uom } : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setUom(option.value);
                                    } else {
                                        setUom(option);
                                    }
                                }}
                                options={uomData.map((opt) => ({
                                    value: opt.name,
                                    label: opt.name,
                                }))}
                            />
                        </div>

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="uom"
                            >
                                Finish
                            </label>
                            <Select
                                id="finish"
                                value={finish ? { value: finish, label: finish } : null}
                                onChange={(option) => {
                                    if (typeof option === 'object' && option !== null) {
                                        setFinish(option.value);
                                    } else {
                                        setFinish(option);
                                    }
                                }}
                                options={finishData.map((opt) => ({
                                    value: opt.name,
                                    label: opt.name,
                                }))}
                            />
                        </div>
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="thickness"
                            >
                                Thickness (in MM)
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="thickness"
                                type="text"
                                value={thickness}
                                onChange={(event) => setThickness(event.target.value)}
                                placeholder="Enter thickness"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mb-6 items-center w-full">
                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="color"
                            >
                                Color
                            </label>
                            <input
                                className="block w-full px-4 py-3 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="color"
                                type="text"
                                value={color}
                                placeholder="Eneter product color"
                                onChange={(event) => setColor(event.target.value)}
                            />
                        </div>

                        <div>
                            <span className=" flex text-xl gap-5">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="product_in_date"
                                >
                                    Date
                                </label>
                            </span>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="productInDate"
                                type="date"
                                value={productInDate}
                                onChange={(event) => setProductInDate(event.target.value)}
                            />
                        </div>

                        {product.dis_continue === '1' ? (

                            <div className="block w-full px-4 py-3 border border-gray-300 rounded mt-7 text-gray-700 focus:ring focus:ring-orange-200">
                                <label className="flex items-center">
                                    <input
                                        // checked={isActive === '0'}
                                        type="checkbox"
                                        className="mr-2"
                                        onChange={(event) => setIsActive('0')}
                                    />
                                    <span className="text-sm font-medium text-gray-700">
                                        Discontinue
                                    </span>
                                </label>
                            </div>
                        ) : (
                            <div className="block w-full px-4 py-3 border border-gray-300 rounded mt-7 text-gray-700 focus:ring focus:ring-orange-200">                                <label className="flex items-center">
                                <input
                                    // checked={isActive === '0'}
                                    type="checkbox"
                                    className="mr-2"
                                    onChange={(event) => setIsActive('1')}
                                />
                                <span className="text-sm font-medium text-gray-700">
                                    Continue
                                </span>
                            </label>
                            </div>
                        )}

                        <div>
                            <label
                                className="block text-sm font-medium text-gray-700 mb-2"
                                htmlFor="image"
                            >
                                Image
                            </label>
                            <input
                                className="block w-full px-4 py-2 border border-gray-300 rounded text-gray-700 focus:ring focus:ring-orange-200"
                                id="image"
                                type="file"
                                onChange={(event) => {
                                    const file = event.target.files[0];
                                    setImage(file);
                                }}
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-2">Description</label>
                            <button type="button" onClick={openModal} className="bg-gray-200 text-gray-700 px-4 py-2 rounded">
                                Add Description
                            </button>
                        </div>

                        <Modal open={isModalOpen} onClose={closeModal}>
                            <div className="modal-content" style={{ padding: '20px', background: 'white', borderRadius: '8px', width: '400px', margin: 'auto', marginTop: '100px' }}>
                                <h2>Add Description</h2>
                                <TextField
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    value={desc}
                                    onChange={(event) => setDesc(event.target.value)}
                                    placeholder="Enter product description here..."
                                />
                                <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Button variant="contained" color="primary" onClick={handleSave}>
                                        Save
                                    </Button>
                                    <Button variant="outlined" color="secondary" onClick={closeModal}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <span className="flex items-center justify-center">
                        <button
                            className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                            type="submit"
                        >
                            {updateStatus === 'pending' ? 'Submitting' : 'Submit'}
                        </button>

                    </span>

                </form>
            </div>
        </>
    );
};

export default UpdateProduct;
