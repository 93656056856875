import { Link, useLocation } from 'react-router-dom';
import {
    FaBars,
    FaFileAlt,
    FaAngleDown,
    FaBoxOpen,
    FaRuler,
    FaBriefcase,
    FaBox,
    FaHome,
    FaHeart,
    FaArchive,
    FaShoppingCart,
    FaReply,
    FaBan
} from 'react-icons/fa';
import { useState } from 'react';
const AdminSidebar = ({ isOpen, toggleSidebar }) => {
    const location = useLocation();
    const godown = localStorage.getItem('godown');
    const [isOpenUsers, setIsOpenUsers] = useState(false);
    const [inventory, setInventory] = useState(false);
    const [report, setReport] = useState(false);
    const [product, setProduct] = useState(false);
    const isActive = (path) => (location.pathname === path ? 'bg-purple-700 text-white' : '');

    return (
        <div
            className={`z-50 bg-gray-800 fixed overflow-y-auto text-white h-screen ${isOpen ? 'w-64' : 'w-16'} transition-all duration-300`}
        >
            <div className="flex flex-col h-full">
                {/* Header Section */}
                <div className="flex items-center justify-between p-4" onClick={toggleSidebar}>
                    {isOpen && (
                        <div className={`flex items-center space-x-2 w-full`}>
                            <h1 className={`text-xl font-bold mt-1 cursor-pointer text-purple-200`}>{godown}</h1>
                        </div>
                    )}
                    {/* Toggle Button */}
                    <button
                        className={`text-white ${isOpen ? 'right-3' : 'left-3'} hover:text-gray-200 transition-colors p-2 rounded-full focus:outline-none absolute top-4`}
                        onClick={toggleSidebar}
                    >
                        <FaBars className={`h-5 w-5 text-white transition duration-300 transform hover:scale-110 hover:rotate-45`} />
                    </button>
                </div>

                {/* Sidebar Links */}
                <ul className="flex-1 mt-3 overflow-y-auto">
                    <Link
                        to="/admin"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/admin')}`}
                    >
                        <FaHome className={`h-7 w-7 text-blue-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Dashboard</span>}
                    </Link>

                    <Link
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/admin-products')} ${isActive('/hold')} hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                        onClick={() => setProduct(!product)}
                    >
                        <FaBox className={`h-7 w-7 text-red-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Products</span>}
                        {isOpen && (
                            <FaAngleDown
                                className={`h-5 w-7 text-white transition duration-300 transform hover:scale-110 ml-2 ${product ? 'rotate-180' : ''}`}
                            />
                        )}
                    </Link>

                    {isOpen && product && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/admin-products"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/admin-products')}`}
                                >
                                    <FaBox className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">All Products</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/hold"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/hold')}`}
                                >
                                    <FaBoxOpen className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">On Hold</span>
                                </Link>
                            </li>
                        </ul>
                    )}

                    <Link
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/admin-stock-in')} ${isActive('/damaged')} ${isActive('/admin-stock-out')} ${isActive('/return')}  hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                        onClick={() => setInventory(!inventory)}
                    >
                        <FaShoppingCart className="h-7 w-7 text-green-500" />
                        {isOpen && <span className="font-medium text-gray-200">Stock</span>}
                        {isOpen && (
                            <FaAngleDown
                                className={`h-5 text-white transition duration-300 transform hover:scale-110 ml-2 ${inventory ? 'rotate-180' : ''}`}
                            />
                        )}
                    </Link>

                    {isOpen && inventory && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/admin-stock-in"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/admin-stock-in')}`}
                                >
                                    <FaBox className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stock In</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/admin-stock-out"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/admin-stock-out')}`}
                                >
                                    <FaBoxOpen className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stock Out</span>
                                </Link>
                                <Link
                                    to="/return"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/return')}`}
                                >
                                    <FaReply className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Return</span>
                                </Link>
                                <Link
                                    to="/damaged"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/damaged')}`}
                                >
                                    <FaBan className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Damaged</span>
                                </Link>
                            </li>
                        </ul>
                    )}
                    <Link
                        to="/admin-category"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/admin-category')}`}
                    >
                        <FaArchive className={`h-7 w-7 text-white`} />
                        {isOpen && <span className="font-medium text-gray-200">Category</span>}
                    </Link>


                    <Link
                        to="/admin-measures"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/admin-measures')}`}
                    >
                        <FaRuler className={`h-7 w-7 text-red-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Measurement</span>}
                    </Link>

                    <Link
                        to="/admin-brand"
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 hover:bg-purple-700 m-2 rounded-lg transition-colors ${isActive('/admin-brand')}`}
                    >
                        <FaBriefcase className={`h-7 w-7 text-orange-500`} />
                        {isOpen && <span className="font-medium text-gray-200">Brand</span>}
                    </Link>

                    <Link
                        className={`flex items-center space-x-4 py-2 px-2 gap-4 ${isActive('/in')} ${isActive('/out')} ${isActive('/returned-in')} ${isActive('/damaged-out')} hover:bg-purple-700 m-2 rounded-lg transition-colors ${isOpen ? 'text-gray-400' : 'text-white'}`}
                        onClick={() => setReport(!report)}
                    >
                        <FaFileAlt className="h-7 w-7 text-green-500" />
                        {isOpen && <span className="font-medium text-gray-200">Reports</span>}
                        {isOpen && (
                            <FaAngleDown
                                className={`h-5 w-7 text-white transition duration-300 transform hover:scale-110 ml-2 ${report ? 'rotate-180' : ''}`}
                            />
                        )}
                    </Link>

                    {isOpen && report && (
                        <ul className={`flex flex-col ml-12 mr-8`}>
                            <li>
                                <Link
                                    to="/report-in"
                                    className={`flex items-center space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/in')}`}
                                >
                                    <FaBox className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stocks In</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/report-out"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/out')}`}
                                >
                                    <FaBoxOpen className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Stocks Out</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/returned-in"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/returned-in')}`}
                                >
                                    <FaReply className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Returns</span>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/damaged-out"
                                    className={`flex items-center mt-1 space-x-4 py-2 px-4 gap-4 hover:bg-purple-700 rounded-lg transition-colors ${isActive('/damaged-out')}`}
                                >
                                    <FaBan className="h-5 w-5 text-white" />
                                    <span className="font-medium text-gray-200">Damaged</span>
                                </Link>
                            </li>
                        </ul>
                    )}
                </ul>
                {isOpen && (
                    <span className='flex flex-col '>
                        <span className='text-xs tracking-tighter  -mb-2' style={{ display: 'inline-flex', alignItems: 'center' }}>
                            Made with <FaHeart className="h-3 w-3 m-2 text-red-500" /> by Ruminate IT Solutions
                        </span>
                        <span className='text-xs tracking-tighter mb-0' style={{ display: 'inline-flex', alignItems: 'center' }}>
                            version : 1.0.0.0
                        </span>
                    </span>
                )}
            </div>
        </div>
    );
};

export default AdminSidebar;
