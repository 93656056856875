import { useEffect, useState } from 'react';
import { addUser, getUsers } from '../../../redux/Slices/SuperAdmin/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../../redux/Slices/SuperAdmin/rolesSlice';
import { getGodown } from '../../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const AddUser = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('');
    const [godown, setGodown] = useState('');
    const [empCode, setEmpCode] = useState('');
    const { AllUsers } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const { addStatus, error } = useSelector((state) => state.user);
    const { userData = [] } = useSelector(state => state.superAdminRoles);
    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');


    useEffect(() => {
        dispatch(getRoles());
        dispatch(getGodown());
        dispatch(getUsers());
    }, [dispatch]);

    const handleSubmit = (event) => {
        event.preventDefault();

        let validationErrors = {};

        // Basic validation
        if (!firstName) validationErrors.firstName = "*";
        if (!email) validationErrors.email = "*";
        if (!phone) validationErrors.phone = "*";
        if (!role) validationErrors.role = "*";
        if (!empCode) validationErrors.empCode = "*";

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email && !emailPattern.test(email)) {
            validationErrors.email = "*";
        }

        if (phone && !/^\d{10}$/.test(phone.trim())) {
            validationErrors.phone = "*";
        }

        const existingUser = AllUsers.find(user => user.empCode === empCode);
        if (existingUser) {
            validationErrors.empCode = "* Already exists ! ";
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});
        dispatch(addUser({
            name: firstName,
            last_name: lastName,
            email,
            phone,
            password,
            role,
            godowns_id: godown,
            empCode
        }));
    };

    return (
        <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-md mt-6">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="first-name"
                        >
                            First Name
                            {errors.firstName && <span className='text-red-500 ml-10 text-xl'>{errors.firstName}</span>}
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="first-name"
                            type="text"
                            value={firstName}
                            onChange={(event) => setFirstName(event.target.value)}
                            placeholder="Enter first name"
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="last-name"
                        >
                            Last Name
                            {errors.lastName && <span className='text-red-500 ml-10 text-xl'>{errors.lastName}</span>}

                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="last-name"
                            type="text"
                            value={lastName}
                            onChange={(event) => setLastName(event.target.value)}
                            placeholder="Enter last name"
                        />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="email"
                        >
                            Email
                            {errors.email && <span className='text-red-500 ml-10 text-xl'>{errors.email}</span>}
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="email"
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Enter email"
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="phone"
                        >
                            Phone
                            {errors.phone && <span className='text-red-500 ml-10 text-xl'>{errors.phone}</span>}
                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="phone"
                            type="tel"
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            placeholder="Enter phone number"
                        />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="empCode"
                        >
                            Emp Code
                            {errors.empCode && <span className='text-red-500 ml-10'>{errors.empCode}</span>}

                        </label>
                        <input
                            className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="emp_code"
                            type="text"
                            value={empCode}
                            onChange={(event) => setEmpCode(event.target.value)}
                            placeholder="Enter emp code"
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                        <label
                            className="block text-sm font-medium text-gray-700 mb-2"
                            htmlFor="role"
                        >
                            Role
                            {errors.role && <span className='text-red-500 ml-10 text-xl'>{errors.role}</span>}
                        </label>
                        <select
                            className="block w-full px-4 py-4 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                            id="role"
                            value={role}
                            onChange={(event) => setRole(event.target.value)}
                        >
                            <option value="">Select Role</option>
                            <option value="1">Super Admin </option>
                            <option value="2">Godown Admin</option>
                            <option value="3">App User</option>
                            <option value="4">Sub Admin</option>
                        </select>
                    </div>
                    {role === '2' && (
                        <>
                            <div className="w-full md:w-1/2 px-3 mb-4 mt-4 md:mb-0">
                                <label
                                    className="block text-sm font-medium text-gray-700 mb-2"
                                    htmlFor="godown"
                                >
                                    Godown
                                </label>
                                <select
                                    className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                                    id="godown"
                                    value={godown}
                                    onChange={(event) => setGodown(event.target.value)}
                                >
                                    <option value="">Select Godown</option>
                                    {godownData.map((godown) => (
                                        <option key={godown.id} value={godown.id}>
                                            {godown.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mt-4">
                                <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="password">
                                    Password
                                </label>
                                <div className="relative">
                                    <input
                                        className="block w-full px-4 py-3 border border-gray-300 rounded-lg text-gray-700 focus:ring focus:ring-orange-200"
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        placeholder="Enter password"
                                    />
                                    {showPassword ? (
                                        <AiOutlineEyeInvisible
                                            className="absolute h-5 w-5 right-3 top-4 cursor-pointer"
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    ) : (
                                        <AiOutlineEye
                                            className="absolute h-5 w-5 right-3 top-4 cursor-pointer"
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                </div>

                <div className="text-center">
                    <button
                        className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg shadow-md focus:outline-none transition ease-in-out duration-300"
                        type="submit"
                    >
                        Add User
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddUser;
