import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Dashboard from './Pages/SuperAdmin/Dashboard/Dashboard';
import Login from './Pages/auth/Login';
import Category from './Pages/SuperAdmin/Category/Category';
import Roles from './Pages/SuperAdmin/Users/Roles';
import Godown from './Pages/SuperAdmin/Inventory/Godowns/Godown';
import Brand from './Pages/SuperAdmin/Inventory/Brand/Brand';
import User from './Pages/SuperAdmin/Users/User';
import Product from './Pages/Products/Product';
import Measures from './Pages/Products/Measures';
import Admin from './Pages/Admin/Admin';
import AdminLayout from './Pages/Admin/Layout/AdminLayout';
import Godowns from './Pages/Admin/Godown/Godown';
import Brands from './Pages/Admin/Brand/Brands';
import Products from './Pages/Admin/Products/Products';
import Categories from './Pages/Admin/Products/Categories';
import Measurement from './Pages/Admin/Products/Measurement';
import ProtectedRoute from './components/utils/Protected/ProtectedRoute';
import GodownLayout from './Pages/Godown/Layout/GodownLayout';
import GodownDash from './Pages/Godown/GodownDash';
import GodownWarehouse from './Pages/Godown/GodownWarehouse';
import GodownProduct from './Pages/Godown/GodownProduct';
import NewStock from './Pages/Products/NewStock';
import StockIn from './Pages/Admin/Products/StockIn';
import StockOut from './Pages/Products/StockOut';
import AdminStockOut from './Pages/Admin/Products/AdminStockOut';
import StockinReport from './Pages/Report/StockinReport';
import StockoutReport from './Pages/Report/StockoutReport';
import Logs from './Pages/SuperAdmin/Users/Logs';
import DeviceLog from './Pages/SuperAdmin/Devices/DeviceLog';
import Hold from './Pages/Products/HoldProducts/Hold';
import InReport from './Pages/Admin/Report/InReport';
import OutReport from './Pages/Admin/Report/OutReport';
import Return from './Pages/Products/Return';
import Broken from './Pages/Products/Broken';
import RetunredIn from './Pages/Admin/Report/RetunredIn';
import DamagedOut from './Pages/Admin/Report/DamagedOut';
import { useSelector } from 'react-redux';
import SuperStockIn from './Pages/SuperAdmin/Stocks/SuperStockIn';
import SuperStockOut from './Pages/SuperAdmin/Stocks/SuperStockOut';
import SuperReturn from './Pages/SuperAdmin/Stocks/SuperReturn';
import SuperDamage from './Pages/SuperAdmin/Stocks/SuperDamage';
import SuperReturnReport from './Pages/SuperAdmin/Report/SuperReturnReport';
import SuperDamageReport from './Pages/SuperAdmin/Report/SuperDamageReport';
import Finish from './Pages/Products/Finish';
import CategoryUpdate from './Pages/WebPart/CategoryUpdate';
import Banner from './Pages/WebPart/Banner';
import Orders from './Pages/WebPart/Orders';
import Enquiry from './Pages/WebPart/Enquiry';

const App = () => {

  const { roleId } = useSelector((state) => state.auth);
  console.log('roleeee ' + roleId)
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        {(roleId === '1' || roleId === '4') ? (
          <Route path="/" element={<Layout />} >
            <Route path="/hold" element={<Hold />} />
            <Route path="/finish" element={<Finish />} />
          </Route>
        ) : (
          <Route path="/" element={<AdminLayout />} >
            <Route path="/hold" element={<Hold />} />
            <Route path="/finish" element={<Finish />} />

          </Route>
        )}

        {/* super admin */}
        <Route path="/" element={<Layout />} >
          <Route path="super-admin" element={<ProtectedRoute element={<Dashboard />} allowedRoles={['1', '4']} />} />
          <Route path="/category" element={<ProtectedRoute element={<Category />} allowedRoles={['1', '4']} />} />
          <Route path="/roles" element={<ProtectedRoute element={<Roles />} allowedRoles={['1', '4']} />} />
          <Route path="/products" element={<ProtectedRoute element={<Product />} allowedRoles={['1', '4']} />} />
          <Route path="/stock-in" element={<ProtectedRoute element={<SuperStockIn />} allowedRoles={['1', '4']} />} />
          <Route path="/returns" element={<ProtectedRoute element={<SuperReturn />} allowedRoles={['1', '4']} />} />
          <Route path="/damages" element={<ProtectedRoute element={<SuperDamage />} allowedRoles={['1', '4']} />} />
          <Route path="/stock-out" element={<ProtectedRoute element={<SuperStockOut />} allowedRoles={['1', '4']} />} />
          <Route path="/users-list" element={<ProtectedRoute element={<User />} allowedRoles={['1', '4']} />} />
          <Route path="/super-godown" element={<ProtectedRoute element={<Godown />} allowedRoles={['1', '4']} />} />
          <Route path="/super-brand" element={<ProtectedRoute element={<Brand />} allowedRoles={['1', '4']} />} />
          <Route path="/measures" element={<ProtectedRoute element={<Measures />} allowedRoles={['1', '4']} />} />
          <Route path="/logs" element={<ProtectedRoute element={<Logs />} allowedRoles={['1', '4']} />} />
          <Route path="/devices" element={<ProtectedRoute element={<DeviceLog />} allowedRoles={['1', '4']} />} />
          <Route path="/in" element={<ProtectedRoute element={<StockinReport />} allowedRoles={['1', '4']} />} />
          <Route path="/out" element={<ProtectedRoute element={<StockoutReport />} allowedRoles={['1', '4']} />} />
          <Route path="/return-rep" element={<ProtectedRoute element={<SuperReturnReport />} allowedRoles={['1', '4']} />} />
          <Route path="/damage-rep" element={<ProtectedRoute element={<SuperDamageReport />} allowedRoles={['1', '4']} />} />
          <Route path="/web/category" element={<ProtectedRoute element={<CategoryUpdate />} allowedRoles={['1']} />} />
          <Route path="/web/banner" element={<ProtectedRoute element={<Banner />} allowedRoles={['1']} />} />
          <Route path="/web/orders" element={<ProtectedRoute element={<Orders />} allowedRoles={['1']} />} />
          <Route path="/web/enquiry" element={<ProtectedRoute element={<Enquiry />} allowedRoles={['1']} />} />
        </Route>

        {/* admin */}
        <Route path="/" element={<AdminLayout />} >
          <Route path="/admin" element={<ProtectedRoute element={<Admin />} allowedRoles={['2']} />} />
          <Route path="/admin-godown" element={<ProtectedRoute element={<Godowns />} allowedRoles={['2']} />} />
          <Route path="/admin-brand" element={<ProtectedRoute element={<Brands />} allowedRoles={['2']} />} />
          <Route path="/admin-products" element={<ProtectedRoute element={<Products />} allowedRoles={['2']} />} />
          <Route path="/admin-category" element={<ProtectedRoute element={<Categories />} allowedRoles={['2']} />} />
          <Route path="/admin-measures" element={<ProtectedRoute element={<Measurement />} allowedRoles={['2']} />} />
          <Route path="/admin-stock-in" element={<ProtectedRoute element={<StockIn />} allowedRoles={['2']} />} />
          <Route path="/admin-stock-out" element={<ProtectedRoute element={<AdminStockOut />} allowedRoles={['2']} />} />
          <Route path="/report-in" element={<ProtectedRoute element={<InReport />} allowedRoles={['2']} />} />
          <Route path="/report-out" element={<ProtectedRoute element={<OutReport />} allowedRoles={['2']} />} />
          <Route path="/return" element={<ProtectedRoute element={<Return />} allowedRoles={['2']} />} />
          <Route path="/damaged" element={<ProtectedRoute element={<Broken />} allowedRoles={['2']} />} />
          <Route path="/returned-in" element={<ProtectedRoute element={<RetunredIn />} allowedRoles={['2']} />} />
          <Route path="/damaged-out" element={<ProtectedRoute element={<DamagedOut />} allowedRoles={['2']} />} />

        </Route>

        {/* Godown Role */}
        <Route path="/" element={<GodownLayout />} >
          <Route path="/$godown" element={<ProtectedRoute element={<GodownDash />} allowedRoles={['3']} />} />
          <Route path="/warehouse" element={<ProtectedRoute element={<GodownWarehouse />} allowedRoles={['3']} />} />
          <Route path="/godown-products" element={<ProtectedRoute element={<GodownProduct />} allowedRoles={['3']} />} />
        </Route>
      </Routes>
    </Router >
  );
};

export default App;
