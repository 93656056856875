import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography, Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getGodown, updateGodown } from '../../../redux/Slices/SuperAdmin/inventory/godownSlice';

const LocationUpdateModal = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const { godownData } = useSelector(state => state.superAdminGodown);

    const [selectedGodown, setSelectedGodown] = useState('');
    const [latitude, setLatitude] = useState('');
    const [showroomname, setShowroom] = useState('');
    const [longitude, setLongitude] = useState('');

    useEffect(() => {
        dispatch(getGodown());
    }, [dispatch]);

    const handleGodownSelect = (e) => {
        const selectedId = e.target.value;
        const godown = godownData.find(g => g.id === parseInt(selectedId));
        setSelectedGodown(godown);
        setLatitude(godown.latitude || '');
        setLongitude(godown.longitude || '');
    };

    const handleUpdateLocation = (e) => {
        e.preventDefault();
        if (selectedGodown) {
            const payload = {
                id: selectedGodown.id,
                name: selectedGodown.name,
                showroomname,
                latitude,
                longitude
            };
            dispatch(updateGodown(payload));

            setSelectedGodown('');
            setLatitude('');
            setLongitude('');
            setShowroom('');

            // onClose(); 
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 2,
                    p: 4,
                    width: '400px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <Typography variant="h6" color="textPrimary" gutterBottom>Update Godown Location</Typography>
                <form onSubmit={handleUpdateLocation}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>Select Godown</InputLabel>
                        <Select
                            onChange={handleGodownSelect}
                            value={selectedGodown ? selectedGodown.id : ''}
                            label="Select Godown"
                        >
                            <MenuItem value="" disabled>Select a godown</MenuItem>
                            {godownData.map(godown => (
                                <MenuItem key={godown.id} value={godown.id}>{godown.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Showroom"
                        variant="outlined"
                        fullWidth
                        value={showroomname}
                        onChange={(e) => setShowroom(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Latitude"
                        variant="outlined"
                        fullWidth
                        value={latitude}
                        onChange={(e) => setLatitude(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        label="Longitude"
                        variant="outlined"
                        fullWidth
                        value={longitude}
                        onChange={(e) => setLongitude(e.target.value)}
                        margin="normal"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Update Location
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default LocationUpdateModal;