// src/components/layout/Navbar.js
import { FaBars, FaUserCircle, FaPowerOff } from 'react-icons/fa'; // Import React Icons
import { logout } from '../../../redux/Slices/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const GodownTopbar = ({ toggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLogout = () => {
        dispatch(logout());
        navigate('/');
    }

    return (
        <nav className=" bg-gray-800 shadow-lg fixed top-0 left-0 right-0 z-30 p-4">
            <div className="container mx-auto flex justify-between items-center">
                {/* Toggle button for sidebar (if needed) */}
                <button
                    onClick={toggleSidebar}
                    className="text-white md:hidden hover:text-gray-400"
                >
                    <FaBars className="h-6 w-6" />
                </button>
                <div className="flex items-center space-x-4 ml-auto">
                    <FaUserCircle className="h-8 w-8 text-white" />
                    <span className="text-white font-medium">Admin</span>
                    <button
                        onClick={onLogout}
                        className="text-white hover:text-gray-400"
                    >
                        <FaPowerOff className="h-6 text-red-500 w-6 hover:scale-110 hover:text-red-400" />
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default GodownTopbar;
