// src/components/Layout/Layout.js
import React, { useState } from 'react';
import GodownSidebar from './GodownSidebar';
import GodownTopbar from './GodownTopbar';
import { Outlet } from 'react-router-dom';

const GodownLayout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex">
            <div className={` ${isSidebarOpen ? 'w-[17rem]' : 'w-20'} transition-all duration-300`}>
                <GodownSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            </div>
            <GodownTopbar toggleSidebar={toggleSidebar} />
            {/* Main Content */}
            <div className={`flex-1 ${isSidebarOpen ? 'max-w-[70rem]' : 'max-w-[95vw]'}  mt-20 transition-all duration-300`}>
                <Outlet />
            </div>
        </div>
    );
};

export default GodownLayout;
