import StockOut from "../../Products/StockOut";
import AdminTopbar from "../Layout/AdminTopbar";


const AdminStockOut = () => {
    return (
        <>
            <AdminTopbar activeTab={'stock out'} />
            <StockOut />
        </>
    )
}

export default AdminStockOut;