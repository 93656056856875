import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from '../../apiConfig';

// Thunk for user login
export const loginUser = createAsyncThunk('auth/loginUser', async (credentials, thunkAPI) => {

    try {
        const response = await fetch(`${apiUrl}login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials),
        });

        const data = await response.json();
        console.log("resp => " + JSON.stringify(data))
        if (!response.ok) {
            return thunkAPI.rejectWithValue(data.error || 'Login failed');
        }

        const { token } = data;
        const { role_id, name, email, grodownsname } = data.user;
        localStorage.setItem('token', token);
        localStorage.setItem('roleId', role_id);
        localStorage.setItem('name', name);
        localStorage.setItem('godown', grodownsname);
        localStorage.setItem('email', email);
        return { token, roleId: role_id, godown: grodownsname, name, email };
    } catch (error) {
        return thunkAPI.rejectWithValue('An error occurred');
    }
});

export const logout = () => (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('roleId');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('godown');
    dispatch(authSlice.actions.logout());
};

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('token') || null,
        roleId: localStorage.getItem('roleId') || null,
        name: localStorage.getItem('name') || null,
        loading: false,
        error: null,
    },
    reducers: {
        logout: (state) => {
            state.token = null;
            state.roleId = null;
            state.name = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.token = action.payload.token;
                state.roleId = action.payload.roleId;
                state.name = action.payload.name;
                state.loading = false;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export default authSlice.reducer;
