import { useEffect, useRef, useState } from "react"
import { getProduct, addProduct, productUpdate, productDelete } from "../../redux/Slices/SuperAdmin/productSlice"
import { useDispatch, useSelector } from "react-redux";
import { FaTrash, FaRegEdit } from 'react-icons/fa';
import AddProduct from "./AddProduct";
import UpdateProduct from "./UpdateProduct";
import { getAdminProduct } from '../../redux/Slices/Admin/adminProductSlice';
import { AiOutlinePicture } from 'react-icons/ai';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TablePagination,
    IconButton,
    Box,
    Typography,
    LinearProgress,
    CircularProgress,
    Modal,
    Tooltip,
    Select,
    MenuItem,
    Grid,
    FormControl,
    InputLabel
} from '@mui/material';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../components/utils/Loader";
import NewStock from "./NewStock";
import { useNavigate } from "react-router-dom";
import { TableView } from "@mui/icons-material";
import { bulkProductAdd, bulkProductAddAdmin } from "../../redux/Slices/BulkProductAdd/bulkAdd";
import { Download as DownloadIcon } from '@mui/icons-material';
import { Upload as UploadIcon } from '@mui/icons-material';
import Topbar from "../../components/Layout/Topbar";
import { getGodown } from "../../redux/Slices/SuperAdmin/inventory/godownSlice";
import { getBrand } from "../../redux/Slices/SuperAdmin/inventory/brandSlice";
import { getCategory } from "../../redux/Slices/SuperAdmin/categorySlice";
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";
import { getSize } from "../../redux/Slices/Measures/sizeSlice";

const Product = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { admBulkStatus, superBulkStatus, err } = useSelector(state => state.bulkProduct);
    const { AllProduct = [], updateStatus, getStatus, addStatus, deleteStatus, error } = useSelector(state => state.product);
    const { AdminProducts = [], adminAddStatus, adminGetStatus, adminUpdateStatus, adminDeleteStatus } = useSelector(state => state.adminProduct);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(AllProduct);
    const [addForm, setAddForm] = useState(false);
    const [updateForm, setUpdateForm] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductQty, setProductQty] = useState();
    const [addStock, setAddStock] = useState(false);
    const { roleId } = useSelector((state) => state.auth);
    const [showImage, setShowImage] = useState(false);
    const [tabelView, setTabelView] = useState(false);
    const [prevStatus, setPrevStatus] = useState({ add: '', update: '', delete: '', bulkStatus: '' });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef(null);
    const [selectedGodown, setSelectedGodown] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedFinish, setSelectedFinish] = useState('');
    const [selectedSize, setSelectedSize] = useState('');

    const handleGodownChange = (event) => {
        setSelectedGodown(event.target.value);
    };

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleFinishChange = (event) => {
        setSelectedFinish(event.target.value);
    };

    useEffect(() => {
        if (roleId === '1') {

            if (addStatus === 'succeeded' && prevStatus.add === 'pending') {
                toast.success('Product added successfully!');
                setPrevStatus({ ...prevStatus, add: addStatus });
            } else if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
                toast.success('Product updated successfully!');
                setPrevStatus({ ...prevStatus, update: updateStatus });
            } else if (deleteStatus === 'succeeded' && prevStatus.delete === 'pending') {
                toast.success('Product deleted successfully!');
                setPrevStatus({ ...prevStatus, delete: deleteStatus });
            } else if (superBulkStatus === 'succeeded' && prevStatus.bulkStatus === 'pending') {
                toast.success('Bulk product added successfully!');
                setPrevStatus({ ...prevStatus, bulkStatus: superBulkStatus });

            } else {
                setPrevStatus({ add: addStatus, update: updateStatus, delete: deleteStatus, bulkStatus: superBulkStatus });
            }
        } else {

            if (adminAddStatus === 'succeeded' && prevStatus.add === 'pending') {
                toast.success('Product added successfully!');
                setPrevStatus({ ...prevStatus, add: adminAddStatus });
            } else if (adminUpdateStatus === 'succeeded' && prevStatus.update === 'pending') {
                toast.success('Product updated successfully!');
                setPrevStatus({ ...prevStatus, update: adminUpdateStatus });
            } else if (admBulkStatus === 'succeeded' && prevStatus.bulkStatus === 'pending') {
                toast.success('Bulk product added successfully!');
                setPrevStatus({ ...prevStatus, bulkStatus: admBulkStatus });
            } else {
                setPrevStatus({ add: adminAddStatus, update: adminUpdateStatus, bulkStatus: admBulkStatus });
            }
        }
    }, [addStatus, updateStatus, deleteStatus, adminAddStatus, adminUpdateStatus, adminDeleteStatus, admBulkStatus, superBulkStatus]);

    const { godownData = [] } = useSelector(state => state.superAdminGodown);
    const { brandData = [] } = useSelector(state => state.superAdminBrand);
    const { sizeData = [] } = useSelector(state => state.superAdminSize);
    const { data = [] } = useSelector(state => state.superAdminCategory);
    const { finishData = [] } = useSelector(state => state.finish);

    useEffect(() => {
        dispatch(getBrand());
        dispatch(getCategory());
        dispatch(getSize());
        dispatch(getGodown());
        dispatch(getFinish());
    }, [])

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAdminProduct())
    }, [
        dispatch,
        updateStatus,
        addStatus,
        deleteStatus,
        adminAddStatus,
        adminDeleteStatus,
        adminUpdateStatus,
        admBulkStatus,
        superBulkStatus
    ])

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        let filteredProducts;

        if (roleId === '1' || roleId === '4') {
            filteredProducts = AllProduct.filter(user =>
                String(user.name).toLowerCase().includes(lowerCaseQuery) ||
                String(user.id).toLowerCase().includes(lowerCaseQuery) ||
                String(user.sku).toLowerCase().includes(lowerCaseQuery) ||
                String(user.categoriename).toLowerCase().includes(lowerCaseQuery) ||
                String(user.brandname).toLowerCase().includes(lowerCaseQuery) ||
                String(user.godownsname).toLowerCase().includes(lowerCaseQuery) ||
                String(user.uom).toLowerCase().includes(lowerCaseQuery) ||
                String(user.finish).toLowerCase().includes(lowerCaseQuery)
            );
        } else {
            filteredProducts = AdminProducts.filter(user =>
                String(user.name).toLowerCase().includes(lowerCaseQuery) ||
                String(user.sku).toLowerCase().includes(lowerCaseQuery) ||
                String(user.categoriename).toLowerCase().includes(lowerCaseQuery) ||
                String(user.brandname).toLowerCase().includes(lowerCaseQuery) ||
                String(user.uom).toLowerCase().includes(lowerCaseQuery) ||
                String(user.finish).toLowerCase().includes(lowerCaseQuery)
            );
        }

        // Apply additional filters
        const filteredData = filteredProducts.filter(product => {
            const matchesGodown = selectedGodown
                ? product.inventories.some(inventory => inventory.godowns_name.name === selectedGodown)
                : true;
            const matchesSize = selectedSize ? product.size === selectedSize : true;
            const matchesBrand = selectedBrand ? product.brandname === selectedBrand : true;
            const matchesCategory = selectedCategory ? product.categoriename === selectedCategory : true;
            const matchesFinish = selectedFinish ? product.finish === selectedFinish : true;

            return matchesGodown && matchesBrand && matchesCategory && matchesFinish && matchesSize;
        });

        setFilteredData(filteredData);
    }, [searchQuery, AllProduct, AdminProducts, selectedGodown, selectedBrand, selectedCategory, selectedFinish, roleId]);

    const handleItemClick = (product) => {
        setSelectedProduct(product);
        setUpdateForm(true);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page2, setPage2] = useState(0);
    const [rowsPerPage2, setRowsPerPage2] = useState(10);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? filteredData.length : parseInt(value, 10));
        setPage(0);
    };

    const handleChangePage2 = (event, newPage) => {
        setPage2(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage2 = (event) => {
        const value = event.target.value;
        setRowsPerPage2(value === 'all' ? filteredDataWithInventories.flatMap(item => item.inventories).length : parseInt(value, 10));
        setPage2(0);
    };

    // const getStatusPromise = getStatus;
    // const updateStatusPromise = updateStatus;
    // const addStatusPromise = addStatus;
    // const deleteStatusPromise = deleteStatus;

    const isPending = getStatus === 'pending' || updateStatus === 'pending' || addStatus === 'pending' || deleteStatus === 'pending' || adminAddStatus === 'pending' || adminGetStatus === 'pending' || adminUpdateStatus === 'pending';

    useEffect(() => {
        if (addStatus === 'succeeded' || adminAddStatus === 'succeeded' || adminUpdateStatus === 'succeeded' || updateStatus === 'succeeded') {
            setAddForm(false);
            setUpdateForm(false);
        }
    }, [addStatus, updateStatus, adminAddStatus, adminUpdateStatus]);


    const filteredDataWithInventories = filteredData.filter(item => item.inventories !== null && item.inventories?.length > 0);
    const inventoriesCount = filteredDataWithInventories.flatMap(item => item.inventories).length;

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setIsUploading(true);
            setUploadProgress(0);
            const reader = new FileReader();
            reader.onload = async (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const productsData = XLSX.utils.sheet_to_json(worksheet);


                // Check for duplicate SKUs in existing products
                const existingProducts = roleId === '1' ? AllProduct : AdminProducts;
                const duplicateSKUs = productsData.filter(product => {
                    return existingProducts.some(existingProduct => {
                        // Convert both to strings for comparison
                        const existingSku = String(existingProduct.sku).trim();
                        const uploadedSku = String(product.sku).trim();
                        return existingSku === uploadedSku;
                    });
                }).map(product => product.sku);
                console.log(duplicateSKUs)

                if (duplicateSKUs.length > 0) {
                    toast.warning(`The following SKUs already exist in the database: ${duplicateSKUs.join(', ')}. Please remove or update these entries.`);
                    console.log('failed')

                    setIsUploading(false);
                    e.target.value = null;
                    return;
                } else {
                    console.log('passed')
                }

                // Check for duplicate SKUs within the file
                const skusInFile = productsData.map(product => product.sku);

                // Filtering  empty SKUs
                const validSkusInFile = skusInFile.filter(sku => sku); // This will keep only non-empty SKUs

                // Checking for duplicate SKUs
                const duplicateSkusInFile = validSkusInFile.filter((sku, index) => validSkusInFile.indexOf(sku) !== index);

                if (duplicateSkusInFile.length > 0) {
                    toast.warning(`The following SKUs are duplicated in the file: ${[...new Set(duplicateSkusInFile)].join(', ')}. Please remove duplicates.`);
                    setIsUploading(false);
                    e.target.value = null;
                    return;
                } else {
                    console.log('cool')
                }

                const filteredData = productsData.map(product => ({
                    id: product.id,
                    name: product.name || '',
                    sku: product.sku || '',
                    size: product.size || '',
                    color: product.color || '',
                    brand: product.brand || '',
                    category_id: product.category_id || 0,
                    sub_category: product.sub_category || 0,
                    product_in_date: product.product_in_date || '',
                    uom: product.uom || '',
                    finish: product.finish || '',
                    thickness: product.thickness || 0,
                    image: product.image || ''
                }));

                const batchSize = 100;
                const batches = Math.ceil(filteredData.length / batchSize);

                for (let i = 0; i < batches; i++) {
                    const start = i * batchSize;
                    const end = start + batchSize;
                    const batch = filteredData.slice(start, end);

                    try {
                        if (roleId === '1') {
                            await dispatch(bulkProductAdd({ bulkProduct: batch }));
                        } else {
                            await dispatch(bulkProductAddAdmin({ bulkProduct: batch }));
                        }

                        // status after each batch
                        if (superBulkStatus === 'failed' || admBulkStatus === 'failed') {
                            throw new Error(err);
                        }

                        //  progress
                        setUploadProgress(((i + 1) / batches) * 100);
                    } catch (error) {
                        console.error(`Error processing batch ${i + 1}:`, error);
                        toast.error(`Something went wrong ${i + 1}: ${JSON.stringify(error.message)}`);
                        setIsUploading(false);
                        return;
                    }
                }

                if (superBulkStatus === 'succeeded' || admBulkStatus === 'succeeded') {
                    // toast.success('Bulk upload completed successfully!');
                }
                setIsUploading(false);
                e.target.value = null;
            };
            reader.readAsArrayBuffer(file);
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleDownloadExcel = () => {
        let tableData;
        if (tabelView) {
            tableData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => ({
                SKU: item.sku,
                Name: item.name,
                Brand: item.brandname,
                Category: item.categoriename,
                SubCategory: item.subcategoriesname,
                Size: item.size,
                UOM: item.uom,
                Color: item.color,
                Finish: item.finish,
                Thickness: item.thickness,
                'Added On': item.product_in_date
            }));
        } else {
            tableData = filteredDataWithInventories.flatMap(item =>
                item.inventories.map(inventory => ({
                    ...item,
                    inventory
                }))
            ).slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2).map((inventoryItem, index) => ({
                ...(roleId === '1' && { Godown: inventoryItem.inventory.godowns_name?.name }), SKU: inventoryItem.sku,
                Name: inventoryItem.name,
                Brand: inventoryItem.brandname,
                Category: inventoryItem.categoriename,
                SubCategory: inventoryItem.subcategoriesname,
                Batch: inventoryItem.inventory.batch_id,
                Size: inventoryItem.size,
                UOM: inventoryItem.uom,
                Color: inventoryItem.color,
                Quantity: inventoryItem.inventory.qty,
                Finish: inventoryItem.finish,
                Thickness: inventoryItem.thickness,
                MRP: inventoryItem.inventory.mrp,
                'Added On': inventoryItem.inventory.product_date
            }));
        }
        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "table_data.xlsx");
    };

    const downloadTemplate = () => {
        // Create workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([
            ['sku', 'name', 'brand', 'category_id', 'sub_category', 'size', 'uom', 'color', 'finish', 'thickness']
        ]);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Product Upload Template');

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create Blob and download link
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'product_upload_template.xlsx';

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    return (

        <>
            <Modal
                open={isUploading}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Uploading...
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Please wait while processing your file.
                    </Typography>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    {/* <CircularProgress variant="determinate" value={uploadProgress} sx={{ display: 'block', margin: 'auto' }} /> */}
                </Box>
            </Modal>
            {(roleId === '1' || roleId === '4') && (<Topbar activeTab={'Products'} />)}
            {isPending && (
                <Loader />
            )}

            <ToastContainer theme="dark" />

            {/* {addStock && (
                <div className="fixed z-20 ml-10  inset-0 flex items-center justify-center mt-12 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg ">
                        <button
                            className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
                            onClick={() => setAddStock(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <NewStock />
                    </div>
                </div>
            )} */}
            {addForm && (

                <div className="fixed z-10 inset-0 flex items-center justify-center mt-12 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg ">
                        <button
                            className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
                            onClick={() => setAddForm(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <AddProduct />
                    </div>
                </div>
            )}
            {updateForm && (

                <div className="fixed inset-0 z-10 flex items-center justify-center mt-12 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg ">
                        <button
                            className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
                            onClick={() => setUpdateForm(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <UpdateProduct product={selectedProduct} />
                    </div>
                </div>
            )}

            <div className="p-3 relative overflow-x-hidden">
                <span className="flex items-center justify-between bg-gradient-to-r pb-14 from-gray-600 to-gray-600 rounded-t-lg">
                    <span className="flex flex-col w-full">
                        {/* top filters  */}
                        <Box display="flex" flexDirection="row" p={2} gap={2}>
                            {roleId === '1' && (
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="godown-select-label" sx={{ color: '#FFFFFF' }}>Godown</InputLabel>
                                    <Select
                                        labelId="godown-select-label"
                                        value={selectedGodown}
                                        onChange={handleGodownChange}
                                        label="Godown"
                                        sx={{
                                            bgcolor: '#90E0',
                                            color: '#FFFFFF',
                                            '& .MuiSelect-icon': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FFFFFF',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root.Mui-focused': {
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiSelect-select': {
                                                color: '#FFFFFF',
                                            },
                                        }}
                                    >
                                        <MenuItem value=""><em >All Godowns</em></MenuItem>
                                        {godownData.map(godown => (
                                            <MenuItem key={godown.id} value={godown.name}>{godown.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="brand-select-label" sx={{ color: '#FFFFFF' }}>Brand</InputLabel>
                                <Select
                                    labelId="brand-select-label"
                                    value={selectedBrand}
                                    onChange={handleBrandChange}
                                    label="Brand"
                                    sx={{
                                        bgcolor: '#90E00',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Brands</em></MenuItem>
                                    {brandData.map(brand => (
                                        <MenuItem key={brand.id} value={brand.name}>{brand.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="category-select-label" sx={{ color: '#FFFFFF' }}>Category</InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    label="Category"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Categories</em></MenuItem>
                                    {data.map(category => (
                                        <MenuItem key={category.id} value={category.name}>{category.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="category-select-label" sx={{ color: '#FFFFFF' }}>Size</InputLabel>
                                <Select
                                    labelId="size-select-label"
                                    value={selectedSize}
                                    onChange={handleSizeChange}
                                    label="Category"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Sizes</em></MenuItem>
                                    {sizeData.map(size => (
                                        <MenuItem key={size.id} value={size.name}>{size.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="finish-select-label" sx={{ color: '#FFFFFF' }}>Finish</InputLabel>
                                <Select
                                    labelId="finish-select-label"
                                    value={selectedFinish}
                                    onChange={handleFinishChange}
                                    label="Finish"
                                    sx={{
                                        bgcolor: '#90E0',
                                        color: '#FFFFFF',
                                        '& .MuiSelect-icon': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root ```javascript': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root.Mui-focused': {
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiSelect-select': {
                                            color: '#FFFFFF',
                                        },
                                    }}
                                >
                                    <MenuItem value=""><em>All Finishes</em></MenuItem>
                                    {finishData.map(finish => (
                                        <MenuItem key={finish.id} value={finish.name}>{finish.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <span className="flex items-center justify-between -mt-4">
                            <span className="flex">
                                <div className="mb-4 m-5">
                                    <input
                                        type="text"
                                        className="rounded w-[20rem] py-2 px-4 border border-gray-400"
                                        placeholder="Search by any field"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </span>
                            <div className="relative w-20 ">
                                <Tooltip title={tabelView ? 'Switch To Available Product' : 'Switch To All Product'} arrow>

                                    <button
                                        className={`w-full h-8 rounded-full p-1 flex items-center justify-between 
                                ${tabelView ? "bg-green-500" : "bg-orange-500"} 
                                transition-colors duration-300 shadow-md`}
                                        onClick={() => setTabelView(!tabelView)}
                                    >
                                        <div
                                            className={`w-5 h-5 bg-white rounded-full transition-transform duration-300 
                            shadow-lg ${tabelView ? "translate-x-12" : "translate-x-1"}`}
                                        />
                                        <span
                                            className={`absolute left-5 text-xs transition-all duration-300 text-white font-semibold 
                              ${tabelView ? "opacity-100" : "opacity-0"}`}
                                        >
                                            AVL
                                        </span>

                                        <span
                                            className={`absolute right-5 text-xs transition-all duration-300 text-white font-semibold 
                             ${tabelView ? "opacity-0" : "opacity-100"}`}
                                        >
                                            ALL
                                        </span>
                                    </button>
                                </Tooltip>
                            </div>
                            <span className="flex">
                                <div className="flex justify-end m-5 mb-4 gap-5">
                                    <Button
                                        variant="contained"
                                        startIcon={<DownloadIcon />}
                                        onClick={handleDownloadExcel}
                                        className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                                    >
                                        Download
                                    </Button>
                                </div>
                                {roleId !== '4' && (
                                    <>
                                        <div className="flex justify-end mt-5 mb-4 gap-5">
                                            <span><label htmlFor="fileInput" className=" font-thin inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600">
                                                <UploadIcon />
                                                BULK ADD
                                            </label>
                                                <input
                                                    id="fileInput"
                                                    type="file"
                                                    className="hidden"
                                                    onChange={handleFileChange}
                                                    ref={fileInputRef}
                                                /></span>
                                            <Tooltip title="Download Template" arrow>
                                                <Button
                                                    variant="contained"
                                                    onClick={downloadTemplate}
                                                    className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                                                >
                                                    <DownloadIcon />
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        <div className="flex justify-end m-5 mb-4 gap-5">
                                            <button className="bg-orange-600 font-thin hover:bg-orange-700 text-white py-2 px-4 rounded shadow-md"
                                                onClick={() => setAddForm(true)}>
                                                ADD PRODUCT
                                            </button>
                                        </div>
                                    </>
                                )}
                            </span>
                        </span>
                    </span>

                </span>

                <div className="overflow-x-auto mt-[-60px]">
                    <Paper >
                        {tabelView === true ? (
                            <>
                                <TableContainer>
                                    <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                                        <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                                            <TableRow>
                                                <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                                <TableCell sx={{ color: 'white', width: '100px' }}>SKU</TableCell>
                                                <TableCell sx={{ color: 'white', width: '200px' }}>Name</TableCell>
                                                <TableCell sx={{ color: 'white', width: '150px' }}>Brand</TableCell>
                                                <TableCell sx={{ color: 'white', width: '150px' }}>Category</TableCell>
                                                <TableCell sx={{ color: 'white', width: '150px' }}>SubCategory</TableCell>
                                                <TableCell sx={{ color: 'white', width: '150px' }}>Size</TableCell>
                                                <TableCell sx={{ color: 'white', width: '100px' }}>UOM</TableCell>
                                                <TableCell sx={{ color: 'white', width: '150px' }}>Color</TableCell>
                                                <TableCell sx={{ color: 'white', width: '140px' }}>Finish</TableCell>
                                                <TableCell sx={{ color: 'white', width: '140px' }}>Thickness</TableCell>
                                                <TableCell sx={{ color: 'white', width: '120px' }}>Added On</TableCell>
                                                <TableCell sx={{ color: 'white', width: '60px' }}>Image</TableCell>
                                                {roleId !== '4' &&
                                                    <TableCell sx={{ color: 'white', width: '100px' }}>Action</TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (

                                                <TableRow key={`${item.id}`} className={`${item.dis_continue === '1' ? '' : ' bg-red-400 '}${item.dis_continue === '0' ? '' : 'hover:bg-gray-200'}`}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{item.sku}</TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.brandname}</TableCell>
                                                    <TableCell>{item.categoriename}</TableCell>
                                                    <TableCell>{item.subcategoriesname}</TableCell>
                                                    <TableCell>{item.size}</TableCell>
                                                    <TableCell>{item.uom}</TableCell>
                                                    <TableCell>{item.color}</TableCell>
                                                    <TableCell>{item.finish}</TableCell>
                                                    <TableCell>{item.thickness}</TableCell>
                                                    <TableCell>
                                                        {new Date(item.product_in_date)?.toLocaleDateString('en-GB').split('/').join('/')}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.ImageUrl !== 'https://inventory.risapp.co.in/public/storage' && (
                                                            <div className="cursor-pointer h-1 w-1 items-center" onClick={() => setShowImage(item.ImageUrl)}>
                                                                <AiOutlinePicture size={24} />

                                                                {showImage === item.ImageUrl && (
                                                                    // Modal Background
                                                                    <div
                                                                        className="fixed inset-0 z-20 flex  items-center justify-center bg-black bg-opacity-10"
                                                                        onClick={() => setShowImage(false)}
                                                                    >
                                                                        {/* Modal Content */}
                                                                        <div
                                                                            className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                                                            style={{ width: '80%', maxWidth: '500px' }}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                        >

                                                                            {/* Image */}
                                                                            <div className="flex justify-center">
                                                                                <img
                                                                                    src={item.ImageUrl}
                                                                                    alt="Image"
                                                                                    className="w-full h-full object-cover rounded-lg"
                                                                                    style={{ maxHeight: '70vh' }}
                                                                                />
                                                                            </div>

                                                                            {/* Action Buttons */}
                                                                            <div className="mt-4 flex justify-center space-x-4">
                                                                                <button
                                                                                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                                    onClick={() => setShowImage(false)}
                                                                                >
                                                                                    Close
                                                                                </button>
                                                                                <button
                                                                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                                    onClick={() => window.open(item.ImageUrl, '_blank')}
                                                                                >
                                                                                    Open in New Tab
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                    {roleId !== '4' &&
                                                        <TableCell>
                                                            <span className="flex h-1 w-1">
                                                                <IconButton onClick={() => handleItemClick(item)} aria-label="edit">
                                                                    <FaRegEdit className="text-blue-700" />
                                                                </IconButton>
                                                                {roleId === '1' && (
                                                                    <IconButton onClick={() => dispatch(productDelete(item.id))} aria-label="delete">
                                                                        <FaTrash className="text-red-600" />
                                                                    </IconButton>
                                                                )}
                                                            </span>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    component="div"
                                    count={filteredData.length}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                                />

                            </>
                        ) : (
                            <>
                                <>
                                    <>
                                        <TableContainer>
                                            <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                                                <TableHead className='bg-gradient-to-r from-gray-600 to-gray-600'>
                                                    <TableRow>
                                                        <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '100px' }}>SKU</TableCell>
                                                        {(roleId === '1' || roleId === '4') && <TableCell sx={{ color: 'white', width: '200px' }}>Godown</TableCell>}
                                                        <TableCell sx={{ color: 'white', width: '200px' }}>Name</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '150px' }}>Brand</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '150px' }}>Category</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '150px' }}>SubCategory</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '150px' }}>Batch</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '150px' }}>Size</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '150px' }}>Color</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '100px' }}>Quantity</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '100px' }}>UOM</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '140px' }}>Finish</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '140px' }}>Thickness</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '100px' }}>MRP</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '120px' }}>Added On</TableCell>
                                                        <TableCell sx={{ color: 'white', width: '90px' }}>Image</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {filteredDataWithInventories
                                                        ?.flatMap(item =>
                                                            item.inventories.map(inventory => ({
                                                                ...item,
                                                                inventory
                                                            }))
                                                        )
                                                        .slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                                                        .map((inventoryItem, index) => (
                                                            <TableRow key={`${inventoryItem.id}-${inventoryItem.inventory.id}`} hover>
                                                                <TableCell>{(page2 * rowsPerPage2) + index + 1}</TableCell> {/* Correct SL No */}
                                                                <TableCell>{inventoryItem.sku}</TableCell>
                                                                {(roleId === '1' || roleId === '4') && <TableCell>{inventoryItem.inventory.godowns_name.name}</TableCell>}
                                                                <TableCell>{inventoryItem.name}</TableCell>
                                                                <TableCell>{inventoryItem.brandname}</TableCell>
                                                                <TableCell>{inventoryItem.categoriename}</TableCell>
                                                                <TableCell>{inventoryItem.subcategoriesname}</TableCell>
                                                                <TableCell>{inventoryItem.inventory.batch_id}</TableCell>
                                                                <TableCell>{inventoryItem.size}</TableCell>
                                                                <TableCell>{inventoryItem.color}</TableCell>
                                                                <TableCell>{inventoryItem.inventory.qty}</TableCell>
                                                                <TableCell>{inventoryItem.uom}</TableCell>
                                                                <TableCell>{inventoryItem.finish}</TableCell>
                                                                <TableCell>{inventoryItem.thickness}</TableCell>
                                                                <TableCell>{inventoryItem.inventory.mrp}</TableCell>
                                                                <TableCell>
                                                                    {inventoryItem.inventory.product_date ? new Date(inventoryItem.inventory.created_at).toLocaleDateString('en-GB').split('/').join('/') : 'NA'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {inventoryItem.ImageUrl !== 'https://inventory.risapp.co.in/public/storage' && (
                                                                        <div className="cursor-pointer h-1 w-1 items-center" onClick={() => setShowImage(inventoryItem.ImageUrl)}>
                                                                            <AiOutlinePicture size={24} />

                                                                            {showImage === inventoryItem.ImageUrl && (
                                                                                // Modal Background
                                                                                <div
                                                                                    className="fixed inset-0 z-20 flex  items-center justify-center bg-black bg-opacity-10"
                                                                                    onClick={() => setShowImage(false)}
                                                                                >
                                                                                    {/* Modal Content */}
                                                                                    <div
                                                                                        className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                                                                        style={{ width: '80%', maxWidth: '500px' }}
                                                                                        onClick={(e) => e.stopPropagation()}
                                                                                    >

                                                                                        {/* Image */}
                                                                                        <div className="flex justify-center">
                                                                                            <img
                                                                                                src={inventoryItem.ImageUrl}
                                                                                                alt="Image"
                                                                                                className="w-full h-full object-cover rounded-lg"
                                                                                                style={{ maxHeight: '70vh' }}
                                                                                            />
                                                                                        </div>

                                                                                        {/* Action Buttons */}
                                                                                        <div className="mt-4 flex justify-center space-x-4">
                                                                                            <button
                                                                                                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                                                onClick={() => setShowImage(false)}
                                                                                            >
                                                                                                Close
                                                                                            </button>
                                                                                            <button
                                                                                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                                                                                onClick={() => window.open(inventoryItem.ImageUrl, '_blank')}
                                                                                            >
                                                                                                Open in New Tab
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <TablePagination
                                            component="div"
                                            count={filteredDataWithInventories.flatMap(item => item.inventories).length}
                                            page={page2}
                                            onPageChange={handleChangePage2}
                                            rowsPerPage={rowsPerPage2}
                                            onRowsPerPageChange={handleChangeRowsPerPage2}
                                            rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                                        />
                                    </>

                                </>

                            </>
                        )}

                    </Paper>
                </div>
            </div >

        </>
    )
}

export default Product