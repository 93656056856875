import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, productDelete } from '../../redux/Slices/SuperAdmin/productSlice';
import { getAdminProduct, productAdminDelete } from "../../redux/Slices/Admin/adminProductSlice";
import AsyncSelect from 'react-select/async';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField, Button, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { stockUpdate, addStock, bulkStockAdd, deleteStock } from '../../redux/Slices/Admin/Stocks/stocksSlice';
import { AiOutlineClose } from "react-icons/ai";
import Loader from "../../components/utils/Loader";
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Upload as UploadIcon } from '@mui/icons-material';

const StockIn = () => {

    const dispatch = useDispatch();
    const { AllProduct = [], deleteStatus } = useSelector((state) => state.product);
    const { AdminProducts = [], adminDeleteStatus, adminGetStatus } = useSelector(state => state.adminProduct);
    const { addStatus, updateStatus, bulkStatus, deleteStat } = useSelector((state) => state.stock);
    const { roleId } = useSelector((state) => state.auth);;
    const [searchInput, setSearchInput] = useState('');
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [cart, setCart] = useState([]);
    const [addedStock, setAddedStock] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [prevStatus, setPrevStatus] = useState({ add: '', delete: '', bulkStatus: '' });
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (addStatus === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('New batch added successfully!');
            setPrevStatus({ ...prevStatus, add: addStatus });
        } else if ((deleteStat === 'succeeded') && prevStatus.delete === 'pending') {
            toast.success('Batch deleted successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStatus });
        } else if ((bulkStatus === 'succeeded') && prevStatus.bulkStatus === 'pending') {
            toast.success('Multiple batches added successfully!');
            setPrevStatus({ ...prevStatus, bulkStatus: bulkStatus });
        } else {
            setPrevStatus({ add: addStatus, delete: deleteStat, bulkStatus: bulkStatus });
        }
    }, [addStatus, deleteStat, bulkStatus]);

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAdminProduct());
    }, [dispatch, addStatus, deleteStatus]);

    // Load options 
    const loadOptions = async (inputValue) => {
        setAddedStock([]);
        setSearchInput(inputValue);

        const uniqueProducts = AdminProducts.reduce((acc, product) => {
            if (!acc.some(p => p.name === product.name)) {
                acc.push({
                    label: `${product.name} (SKU: ${product.sku})`,
                    value: product.name,
                    sku: product.sku,
                });
            }
            return acc;
        }, []);

        return uniqueProducts.filter(product =>
            product.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            product.sku.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const handleSelectChange = (selectedOption) => {
        setAddedStock([])
        if (selectedOption) {
            const relatedProducts = (roleId === '1' ? AllProduct : AdminProducts).filter(product => product.name === selectedOption.value);
            setCart(prevCart => [
                ...prevCart,
                { ...relatedProducts[0], qty: '', batch: '', mrp: '', product_date: new Date().toISOString().split('T')[0] }
            ]);
            setFilteredProducts(relatedProducts);
        }
        setSelectedProduct(null);
    };
    // batch input changes
    const handleBatchChange = (index, field, value) => {

        const updatedCart = [...cart];
        updatedCart[index][field] = value;

        if (field === 'batch') {
            const existingBatch = filteredProducts[0].inventories.find((inventory) => inventory.batch_id === value);
            if (existingBatch) {
                toast.error("This batch already exists. Please enter a unique batch.");
            }
        }
        setCart(updatedCart);

    };
    // Remove product from cart
    const handleRemoveFromCart = (id) => {
        setCart(cart.filter(item => item.id !== id));
    };
    useEffect(() => {
        if (addStatus === 'succeeded') {
            setCart([]);
            setSelectedProduct(null);
            setFilteredProducts([]);
        } else if (addStatus === 'failed') {
            toast.error("Something went wrong. Try again later");

        }
    }, [addStatus])
    //form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        const emptyBatchItems = cart.filter(item => !item.batch);
        const duplicateBatchItems = cart.filter((item, index) =>
            filteredProducts[0].inventories.some(inventory => inventory.batch_id === item.batch)
        );

        if (emptyBatchItems.length > 0) {
            const productNames = emptyBatchItems.map(item => item.name).join(', ');
            toast.error(`Please enter a batch for the following products: ${productNames}`);
            return;
        }

        if (duplicateBatchItems.length > 0) {
            const productDetails = duplicateBatchItems.map(item => `${item.name} (Batch: ${item.batch})`).join(', ');
            toast.error(`Duplicate batch detected for the following products: ${productDetails}. Please ensure all batches are unique.`);
            return;
        }

        const products = cart;

        await dispatch(addStock({ products }));
        setAddedStock(products);
        // setCart([]);
        // setSelectedProduct(null);
        // setFilteredProducts([]);
    };

    const isPending = updateStatus === 'pending' || addStatus === 'pending' || bulkStatus === 'pending' || adminGetStatus == 'pending';

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const productsData = XLSX.utils.sheet_to_json(worksheet);
                console.log('file s data ' + JSON.stringify(productsData))
                const filteredData = productsData.map(product => ({
                    sku_code: product.sku,
                    batch_id: product.batch_id,
                    // sku_code: product.sku || '',
                    qty: product.qty,
                    mrp: product.mrp,
                })).filter(product => product.sku_code && product.batch_id);


                const missingFields = filteredData.filter(product => {
                    return (
                        !product.batch_id ||
                        (typeof product.batch_id === 'string' && product.batch_id.trim() === '') ||
                        !product.sku_code ||
                        (typeof product.sku_code === 'string' && product.sku_code.trim() === '') ||
                        !product.qty ||
                        (typeof product.qty === 'string' && product.qty.trim() === '')
                    );
                });

                if (missingFields.length > 0) {
                    const missingFieldProducts = missingFields.map(item => item.sku_code || "Unknown SKU").join(', ');
                    toast.error(`The following products are missing required fields: ${missingFieldProducts}`);
                    return;
                }


                const existingSkus = AdminProducts.map(product => product.sku.toString());

                const invalidSkus = filteredData.filter(product => !existingSkus.includes(product.sku_code.toString()));

                if (invalidSkus.length > 0) {
                    const invalidSkuList = invalidSkus.map(item => item.sku_code).join(', ');
                    toast.warning(`The following SKUs do not exist: ${invalidSkuList}`);
                    return;
                }

                const duplicateBatchItems = [];
                filteredData.forEach((item) => {
                    const product = AdminProducts.find(product => product.sku == item.sku_code);

                    if (product) {
                        const existingBatch = product.inventories.some(inventory =>
                            inventory.batch_id == item.batch_id
                        );

                        if (existingBatch) {
                            duplicateBatchItems.push(item);
                        }
                    }
                });

                if (duplicateBatchItems.length > 0) {
                    const batchDetails = duplicateBatchItems.map(item => `${item.sku_code} (Batch: ${item.batch_id})`).join(', ');
                    toast.error(`Duplicate batch detected for the following products: ${batchDetails}. Please ensure all batches are unique.`);
                    return;
                }


                dispatch(bulkStockAdd({ bulkStock: filteredData }));
                e.target.value = null;
                fileInputRef.current.value = null;
            };
            reader.readAsArrayBuffer(file);
        }
        // if (bulkStatus === 'failed') {
        //     toast.error(`Something went wrong. Try again later!`);

        // }
    };

    const downloadTemplate = () => {
        // Create workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([
            ['sku', 'batch_id', 'qty', 'mrp']
        ]);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Stock Upload Template');

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Create Blob and download link
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'stock_upload_template.xlsx';

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    return (
        <>

            <div className={`p-3 min-h-screen text-sm ${(filteredProducts.length > 0 || cart.length > 0) ? '' : ''}`}>
                {isPending && (
                    <Loader />
                )}
                <ToastContainer theme='dark' />
                {/* Stock In Section */}
                <div className="bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg shadow-lg p-6">
                    <span className='flex items-center justify-between pb-4 '>
                        <h2 className="font-semibold text-xl mb-4 text-white">Available Batch</h2>
                        <span className='flex gap-5'>
                            <span >
                                <label htmlFor="fileInput" className="inline-block px-4 py-2  bg-blue-500 text-white  rounded cursor-pointer hover:bg-blue-600">
                                    <UploadIcon />
                                    BULK STOCK
                                </label>
                                <input
                                    id="fileInput"
                                    type="file"
                                    className="hidden"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                />
                            </span>
                            <Tooltip title="Download Template" arrow>
                                <Button
                                    variant="contained"
                                    onClick={downloadTemplate}
                                    className="bg-blue-600 text-white px-4 py-2 hover:bg-blue-700 transition-colors"
                                >
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                        </span>
                    </span>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={handleSelectChange}
                        placeholder="Search for products..."
                        isClearable
                        className=""
                    />

                </div>

                {/* Selected Products / Filtered Products */}
                {filteredProducts.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full text-left border-collapse">
                            <thead className="bg-gradient-to-r pb-14 from-gray-600 to-gray-600 text-white">
                                <tr>

                                    <th className="px-6 py-4">Brand</th>
                                    <th className="px-6 py-4">Category</th>
                                    <th className="px-6 py-4">SKU</th>
                                    <th className="px-6 py-4">Name</th>
                                    <th className="px-6 py-4">Batch</th>
                                    <th className="px-6 py-4">QTY AVL</th>
                                    <th className="px-6 py-4">UOM</th>
                                    <th className="px-6 py-4">MRP</th>
                                    <th className="px-6 py-4">Size</th>
                                    <th className="px-6 py-4">Finish</th>
                                    <th className="px-6 py-4">Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300 bg-white">
                                {filteredProducts.map((product) => (
                                    product.inventories?.map((inventory) => (
                                        <tr key={`${product.id}-${inventory.id}`}>
                                            <td className="px-6 py-4 min-w-[100px]">{product.brandname}</td>
                                            <td className="px-6 py-4 min-w-[190px]">{product.categoriename}</td>
                                            <td className="px-6 py-4 min-w-[100px]">{product.sku}</td>
                                            <td className="px-6 py-4 min-w-[240px]">{product.name}</td>
                                            <td className="px-6 py-4 min-w-[80px]">
                                                <span className="space-y-2" >{inventory.batch_id}<br /></span>
                                            </td>

                                            <td className="px-6 py-4 min-w-[120px]">
                                                <span key={inventory.id}>{inventory.qty} <br /></span>
                                            </td>
                                            <td className="px-6 py-4 min-w-[80px]">{product.uom}</td>
                                            <td className="px-6 py-4 min-w-[80px]">{inventory.mrp}</td>
                                            <td className="px-6 py-4 min-w-[80px]">{product.size}</td>
                                            <td className="px-6 py-4 min-w-[80px]">{product.finish}</td>
                                            <td className="px-6 py-4">
                                                <button
                                                    className={` ${inventory.qty !== '0' ? 'bg-red-300' : 'bg-red-600 hover:bg-red-400'} ${inventory.qty !== '0' ? 'cursor-not-allowed' : 'cursor-pointer'} text-white p-2 rounded-full`}
                                                    disabled={inventory.qty !== '0'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        dispatch(deleteStock(inventory.id));
                                                    }}
                                                >
                                                    <DeleteIcon className="text-white" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ))}
                            </tbody>

                        </table>
                    </div>
                ) : (
                    <p className=" text-center">No products selected</p>
                )}

                {/* Selected Products Table */}
                {cart.length > 0 && (
                    <div className="bg-gray-100 p-4 rounded-xl shadow-xl mt-6">
                        <h3 className="font-bold mb-6 text-gray-800">New Stock Batch</h3>
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full text-left border-collapse">
                                <thead className="bg-gradient-to-r pb-14 from-gray-600 to-gray-600 text-white">
                                    <tr>
                                        <th className="px-6 py-4">SKU</th>
                                        <th className="px-6 py-4">Name</th>
                                        <th className="px-6 py-4">Batch</th>
                                        <th className="px-6 py-4">Quantity</th>
                                        <th className="px-6 py-4">MRP</th>
                                        <th className="px-6 py-4">Date</th>
                                        <th className="px-6 py-4">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300 bg-white">
                                    {cart.map((item, index) => (
                                        <tr key={item.id}>
                                            <td className="px-6 py-1">{item.sku}</td>
                                            <td className="px-6 py-1">{item.name}</td>
                                            <td className="px-6 py-1">
                                                <TextField
                                                    value={item.batch}
                                                    onChange={(e) => handleBatchChange(index, 'batch', e.target.value)}
                                                    className="w-24 border border-gray-300 rounded-lg p-2"
                                                    placeholder="Batch"
                                                />
                                            </td>
                                            <td className="px-6 py-1">
                                                <TextField
                                                    type="number"
                                                    value={item.quantity}
                                                    onChange={(e) => handleBatchChange(index, 'qty', e.target.value)}
                                                    className="w-24 border border-gray-300 rounded-lg p-2"
                                                    placeholder="Quantity"
                                                />
                                            </td>
                                            <td className="px-6 py-1">
                                                <TextField
                                                    type="number"
                                                    value={item.mrp}
                                                    onChange={(e) => handleBatchChange(index, 'mrp', e.target.value)}
                                                    className="w-24 border border-gray-300 rounded-lg p-2"
                                                    placeholder="MRP"
                                                />
                                            </td>
                                            <td className="px-6 py-1">
                                                <TextField
                                                    type="date"
                                                    value={item.product_date}
                                                    onChange={(e) => handleBatchChange(index, 'product_date', e.target.value)}
                                                    className="w-36 border border-gray-300 rounded-lg p-2"
                                                />
                                            </td>
                                            <td className="px-6 py-1">
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleRemoveFromCart(item.id)}
                                                    className="hover:bg-red-100 p-2 rounded-full"
                                                >
                                                    <AiOutlineClose size={22} className='bg-red-200 h-10 w-10 font-thin rounded-full p-3 text-black' />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-center mt-8">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                className="py-3 px-6 font-semibold bg-green-500 hover:bg-green-600 transition-all text-white rounded-lg shadow-lg transform hover:scale-105"
                            >
                                Stock In
                            </Button>
                        </div>
                    </div>
                )}

                {(cart.length === 0 && addedStock.length > 0) && (
                    <div className="bg-gray-100 p-8 rounded-xl shadow-xl mt-6">
                        <h3 className="font-bold mb-6 text-gray-800"> Stocks Added</h3>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>SKU</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Batch</TableCell>
                                        <TableCell>QTY</TableCell>
                                        <TableCell>MRP</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {addedStock.map((product, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{product.sku}</TableCell>
                                            <TableCell>{product.name}</TableCell>
                                            <TableCell>{product.batch}</TableCell>
                                            <TableCell>{product.qty}</TableCell>
                                            <TableCell>{product.mrp}</TableCell>
                                            <TableCell>{new Date(product.product_date).toLocaleDateString('en-GB', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            })}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </div>
        </>
    );
};

export default StockIn;
