import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../redux/Slices/SuperAdmin/categorySlice';
import Topbar from '../../components/Layout/Topbar';
import { subCategoryUpdate } from '../../redux/Slices/SuperAdmin/subCategory';
import { Box, Typography, Input, Paper, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isPending } from '@reduxjs/toolkit';
import Loader from '../../components/utils/Loader';

const InputFile = styled('input')({
    display: 'none',
});

const ImagePreview = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 100,
    borderRadius: '8px',
    border: '2px dashed #3f51b5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    position: 'relative',
    transition: 'border-color 0.3s ease',
    '&:hover': {
        borderColor: '#1976d2',
    },
}));

const IconImage = styled('img')({
    width: 40,
    height: 40,
    borderRadius: '4px',
    cursor: 'pointer',
    margin: '4px',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.1)',
    },
});

const CategoryUpdate = () => {
    const { data = [], getStatus } = useSelector(state => state.superAdminCategory);
    const { updateStat } = useSelector(state => state.subCategory);
    const dispatch = useDispatch();
    const [selectedImages, setSelectedImages] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [showImage, setShowImage] = useState(false);
    const [modalImage, setModalImage] = useState('');
    const [prevStatus, setPrevStatus] = useState({ update: '' });

    useEffect(() => {
        dispatch(getCategory());
    }, [dispatch, updateStat]);

    useEffect(() => {
        if (updateStat === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Image Uploaded!');
            setPrevStatus({ ...prevStatus, update: updateStat });
        } else {
            setPrevStatus({ update: updateStat });
        }
    }, [updateStat]);

    const handleImageChange = (subCatId, name, file) => {
        if (file) {
            dispatch(subCategoryUpdate({ id: subCatId, name, image: file }));
        }
    };

    const handleOpenModal = (imageUrl) => {
        setModalImage(imageUrl);
        setShowImage(true);
    };

    const handleCloseModal = () => {
        setShowImage(false);
        setModalImage('');
    };

    const filteredData = data.filter(category =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        category.subcategories.some(sub => sub.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const isPending = getStatus === 'pending' || updateStat === 'pending';

    return (
        <>
            {isPending && (
                <Loader />
            )}
            <Topbar activeTab={'Category Image Controls'} />
            <ToastContainer theme='dark' />
            <Box sx={{ p: 4, backgroundColor: '#f0f4f8', borderRadius: '8px' }}>
                <Input
                    placeholder="Search categories..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                        mb: 4,
                        padding: '12px',
                        borderRadius: '8px',
                        border: '1px solid #ccc',
                        width: '100%',
                        maxWidth: '400px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                />
                {filteredData.map((category) => (
                    <Box key={category.id} sx={{ mb: 4 }}>
                        <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: '#3f51b5' }}>{category.name}</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 2 // Gap between items
                            }}
                        >
                            {category.subcategories.map((subCategory) => (
                                <Box
                                    key={subCategory.id}
                                    sx={{
                                        flex: '1 1 calc(33.33% - 16px)',
                                        minWidth: 250,
                                    }}
                                >
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            borderRadius: 2,
                                            textAlign: 'center',
                                            backgroundColor: '#ffffff',
                                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                            transition: 'transform 0.2s',
                                            '&:hover': {
                                                transform: 'scale(1.02)',
                                            },
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mb: 1, color: '#555' }}>{subCategory.name}</Typography>
                                        <label htmlFor={`file-input-${subCategory.id}`}>
                                            <ImagePreview>
                                                {selectedImages[subCategory.id] ? (
                                                    <Box
                                                        component="img"
                                                        src={selectedImages[subCategory.id].image}
                                                        alt={subCategory.name}
                                                        sx={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            borderRadius: '8px',
                                                        }}
                                                    />
                                                ) : (
                                                    <Typography variant="body2" sx={{ color: '#aaa' }}>
                                                        Drop image here or click to upload
                                                    </Typography>
                                                )}
                                            </ImagePreview>
                                        </label>
                                        <InputFile
                                            id={`file-input-${subCategory.id}`}
                                            type="file"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                handleImageChange(subCategory.id, subCategory.name, file)
                                            }}
                                        />
                                        {subCategory.subcateimages != "https:\/\/app-api.mpgroupnepal.com\/public\/storage" && (
                                            <IconImage
                                                src={subCategory.subcateimages}
                                                alt={subCategory.name}
                                                onClick={() => handleOpenModal(subCategory.subcateimages)}
                                            />
                                        )}
                                    </Paper>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>

            {/* Modal for displaying the larger image */}
            {showImage && (
                <div
                    className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-10"
                    onClick={handleCloseModal}
                >
                    <div
                        className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                        style={{ width: '80%', maxWidth: '500px' }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Image */}
                        <div className="flex justify-center">
                            <img
                                src={modalImage}
                                alt="Image"
                                className="w-full h-full object-cover rounded-lg"
                                style={{ maxHeight: '70vh' }}
                            />
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-4 flex justify-center space-x-4">
                            <button
                                className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                onClick={handleCloseModal}
                            >
                                Close
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                onClick={() => window.open(modalImage, '_blank')}
                            >
                                Open in New Tab
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CategoryUpdate;
