// src/components/Layout/Layout.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

const Layout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    // Using theme and color mode from custom hook

    return (
        <>
            <CssBaseline /> {/* This ensures consistent styling across browsers */}
            <div className="flex">
                {/* Sidebar with dynamic width based on state */}
                <div className={` ${isSidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300 overflow-auto`}>
                    <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                </div>

                {/* Main Content Area */}
                <div className={`flex-1 mt-20 transition-all duration-300 overflow-auto`}>
                    <Outlet /> {/* Used for rendering child routes */}
                </div>
            </div>
        </>
    );
};

export default Layout;
