import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getAdminProduct } from '../../../redux/Slices/Admin/adminProductSlice';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TablePagination,
    IconButton
} from '@mui/material';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../components/utils/Loader";
import { useNavigate } from "react-router-dom";
import { Download as DownloadIcon } from '@mui/icons-material';
import { releaseHold, releaseHoldSuper } from "../../../redux/Slices/Hold/holdSlice";
import { Undo } from '@mui/icons-material';
import { Modal, Typography } from '@mui/material';
import MessageIcon from '@mui/icons-material/Message';
import Topbar from "../../../components/Layout/Topbar";
import AdminTopbar from "../../Admin/Layout/AdminTopbar";
import { getProduct } from "../../../redux/Slices/SuperAdmin/productSlice";
const Hold = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roleId } = useSelector((state) => state.auth);
    const { AdminProducts = [], adminGetStatus } = useSelector(state => state.adminProduct);
    const { AllProduct = [], getStatus } = useSelector(state => state.product);
    const { holdStatus } = useSelector(state => state.hold);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(roleId === '1' ? AllProduct : AdminProducts);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [prevStatus, setPrevStatus] = useState({ release: '', });
    const [modalOpen, setModalOpen] = useState(false);
    const [currentRemarks, setCurrentRemarks] = useState('');


    useEffect(() => {
        if (holdStatus === 'succeeded') {
            toast.success('Released successfully!');
            setPrevStatus({ ...prevStatus, release: holdStatus });
        } else {
            setPrevStatus({ release: holdStatus });
        }
    }, [holdStatus]);


    useEffect(() => {
        dispatch(getAdminProduct())
        dispatch(getProduct())

    }, [dispatch, holdStatus])

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredData(
            (roleId === '1' ? AllProduct : AdminProducts).filter(item =>
                String(item.name).toLowerCase().includes(lowerCaseQuery) ||
                String(item.brand).toLowerCase().includes(lowerCaseQuery) ||
                String(item.categoriename).toLowerCase().includes(lowerCaseQuery) ||
                String(item.subcategoriesname).toLowerCase().includes(lowerCaseQuery) ||
                String(item.uom).toLowerCase().includes(lowerCaseQuery) ||
                String(item.size).toLowerCase().includes(lowerCaseQuery) ||
                String(item.finish).toLowerCase().includes(lowerCaseQuery) ||
                String(item.thickness).toLowerCase().includes(lowerCaseQuery) ||
                String(item.sku).toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery, AdminProducts, AllProduct]);

    const handleItemClick = (product) => {
        setSelectedProduct(product);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change
    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? filteredDataWithInventories.flatMap(item => item.inventories.flatMap(inventory => inventory.holdqty)).length : parseInt(value, 10));
        setPage(0);
    };

    const isPending = adminGetStatus === 'pending' || holdStatus === 'pending' || getStatus === 'pending';


    const filteredDataWithInventories = filteredData.filter(item =>
        item.inventories !== null &&
        item.inventories.length > 0 &&
        item.inventories.some(inventory =>
            inventory.holdqty !== null &&
            inventory.holdqty.length > 0
        )
    );
    console.log(filteredDataWithInventories)
    const inventoriesCount = filteredDataWithInventories.flatMap(item => item.inventories).length;

    const handleDownloadExcel = () => {
        let tableData;
        tableData = filteredDataWithInventories.flatMap(item =>
            item.inventories.map(inventory => ({
                ...item,
                inventory
            }))
        ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inventoryItem, index) => ({
            Godown: inventoryItem.inventory.godowns_name.name,
            SKU: inventoryItem.sku,
            Name: inventoryItem.name,
            Brand: inventoryItem.brand,
            Category: inventoryItem.categoriename,
            SubCategory: inventoryItem.subcategoriesname,
            Batch: inventoryItem.inventory.batch_id,
            Size: inventoryItem.size,
            UOM: inventoryItem.uom,
            Color: inventoryItem.color,
            Quantity: inventoryItem.inventory.qty,
            Finish: inventoryItem.finish,
            Thickness: inventoryItem.thickness,
            MRP: inventoryItem.inventory.mrp,
            'Added On': inventoryItem.product_date
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "table_data.xlsx");
    };

    const handleOpenModal = (remarks) => {
        setCurrentRemarks(remarks);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };


    return (
        <>
            {isPending && (
                <Loader />
            )}
            {(roleId === '1' || roleId === '4') ? (

                <Topbar activeTab={'holdings'} />
            ) : (

                <AdminTopbar activeTab={'holdings'} />
            )}

            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div style={{ padding: '20px', background: 'white', margin: '100px auto', maxWidth: '500px', borderRadius: '8px' }}>
                    <Typography id="modal-title" variant="h6">Remarks</Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        {currentRemarks || 'No remarks available'}
                    </Typography>
                </div>
            </Modal>


            <ToastContainer theme="dark" />
            <div className="p-3 relative ">
                <span className="flex items-center justify-between bg-gradient-to-r pb-14 from-gray-600 to-gray-600 rounded-t-lg">
                    <span className="flex">
                        <div className="mb-4 m-5">
                            <input
                                type="text"
                                className="rounded w-[20rem] py-2 px-4 border border-gray-400"
                                placeholder="Search by any field"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>

                    </span>
                    <span className="flex">
                        <div className="flex justify-end m-5 mb-4 gap-5">

                            <Button
                                variant="contained"
                                startIcon={<DownloadIcon />}
                                onClick={handleDownloadExcel}
                                className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                            >
                                Download
                            </Button>
                        </div>

                    </span>
                </span>

                <div className="overflow-x-auto mt-[-60px]">
                    <Paper >
                        <TableContainer>
                            <Table aria-label="customized table" sx={{ width: '100%', tableLayout: 'fixed' }}>
                                <TableHead className='bg-gradient-to-r uppercase from-gray-600 to-gray-600'>
                                    <TableRow>
                                        <TableCell sx={{ color: 'white', width: '80px' }}>SL No.</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>SKU</TableCell>
                                        {roleId === '1' && <TableCell sx={{ color: 'white', width: '100px' }}>Godown</TableCell>}
                                        <TableCell sx={{ color: 'white', width: '200px' }}>Name</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Brand</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Category</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>SubCategory</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Batch</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Size</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>UOM</TableCell>
                                        <TableCell sx={{ color: 'white', width: '120px' }}>Total QTY</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>In Hold</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>Avl QTY</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Hold By</TableCell>
                                        <TableCell sx={{ color: 'white', width: '150px' }}>Remarks</TableCell>
                                        <TableCell sx={{ color: 'white', width: '140px' }}>Finish</TableCell>
                                        <TableCell sx={{ color: 'white', width: '140px' }}>Thickness</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>MRP</TableCell>
                                        <TableCell sx={{ color: 'white', width: '100px' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredDataWithInventories
                                        ?.flatMap(item =>
                                            item.inventories.flatMap(inventory =>
                                                inventory.holdqty.map(hold => ({
                                                    ...item,
                                                    inventory,
                                                    holdQty: hold.hold_qty,
                                                    id: hold.id,
                                                    holderName: hold.holdername,
                                                    remarks: hold.remarks,
                                                }))
                                            )
                                        )
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((inventoryItem, index) => (
                                            <TableRow key={`${inventoryItem.id}-${inventoryItem.inventory.id}`} hover>
                                                <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                                                <TableCell>{inventoryItem.sku}</TableCell>
                                                {roleId === '1' && <TableCell>{inventoryItem.inventory.godowns_name?.name}</TableCell>}
                                                <TableCell>{inventoryItem.name}</TableCell>
                                                <TableCell>{inventoryItem.brand}</TableCell>
                                                <TableCell>{inventoryItem.categoriename}</TableCell>
                                                <TableCell>{inventoryItem.subcategoriesname}</TableCell>
                                                <TableCell>{inventoryItem.inventory.batch_id}</TableCell>
                                                <TableCell>{inventoryItem.size}</TableCell>
                                                <TableCell>{inventoryItem.uom}</TableCell>
                                                <TableCell>{inventoryItem.inventory.qty}</TableCell>
                                                <TableCell>{inventoryItem.holdQty}</TableCell>
                                                <TableCell>{inventoryItem.inventory.qty - inventoryItem.inventory.total_hold_qty}</TableCell>
                                                <TableCell className="uppercase">{inventoryItem.holderName}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleOpenModal(inventoryItem.remarks)}>
                                                        <MessageIcon />
                                                    </IconButton>
                                                </TableCell>

                                                <TableCell>{inventoryItem.finish}</TableCell>
                                                <TableCell>{inventoryItem.thickness}</TableCell>
                                                <TableCell>{inventoryItem.inventory.mrp}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            roleId === '1'
                                                                ? dispatch(releaseHoldSuper({ id: inventoryItem.id }))
                                                                : dispatch(releaseHold({ id: inventoryItem.id }));
                                                        }}
                                                    >
                                                        <Undo />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            component="div"
                            count={filteredDataWithInventories.flatMap(item => item.inventories.flatMap(inventory => inventory.holdqty)).length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                        />
                    </Paper>
                </div>
            </div >

        </>
    )
}

export default Hold;