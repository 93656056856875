import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getDamaged = createAsyncThunk(
    "out/getDamaged",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/demage/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getDamagedSuper = createAsyncThunk(
    "damage/getDamagedSuper",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/demage/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getReturned = createAsyncThunk(
    "in/getReturned",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/return/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getReturnedSuper = createAsyncThunk(
    "return/getReturnedSuper",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/return/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    returnData: [],
    damageData: [],
    returnDataSuper: [],
    damageDataSuper: [],
    status: 'idle',
    error: null,
};

const godownReportSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReturned.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getReturned.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.returnData = action.payload.data;
            })
            .addCase(getReturned.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getReturnedSuper.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getReturnedSuper.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.returnDataSuper = action.payload.data;
            })
            .addCase(getReturnedSuper.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getDamaged.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getDamaged.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.damageData = action.payload.data;
            })
            .addCase(getDamaged.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getDamagedSuper.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getDamagedSuper.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.damageDataSuper = action.payload.data;
            })
            .addCase(getDamagedSuper.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
});

export default godownReportSlice.reducer;
