import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getDevice = createAsyncThunk(
    "super_admin/getDevice",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/device
`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateDevice = createAsyncThunk(
    "updateDevice",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/device/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const superDeviceSlice = createSlice({
    name: 'device',
    initialState: {
        deviceData: [],
        getStatus: 'idle',
        updateStatus: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDevice.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getDevice.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.deviceData = action.payload.data;
            })
            .addCase(getDevice.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateDevice.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateDevice.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(updateDevice.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })

    }
});

export default superDeviceSlice.reducer;
