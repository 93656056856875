import NewStock from "../../Products/NewStock";
import AdminTopbar from "../Layout/AdminTopbar";


const StockIn = () => {
    return (
        <>
            <AdminTopbar activeTab={'Stock in'} />
            <NewStock />
        </>
    )
}

export default StockIn