import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUploads, deleteUploads } from '../../redux/Slices/SuperAdmin/web/uploadSlice';
import Topbar from '../../components/Layout/Topbar';
import {
    Box,
    Button,
    Typography,
    Container,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import LocationUpdateModal from '../../components/utils/web/LocationUpdateModal';
import CatalogUploadModal from '../../components/utils/web/CatalogUploadModal';
import Loader from "../../components/utils/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { update } from 'lodash';

const Banner = () => {
    const dispatch = useDispatch();
    const { uploadsData = [], getStat, deleteStat, addStat } = useSelector(state => state.webUploads);
    const { updateStatus } = useSelector(state => state.superAdminGodown);
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [catalogModalOpen, setCatalogModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [prevStatus, setPrevStatus] = useState({ delete: '', add: '', update: '' });

    useEffect(() => {
        if (addStat === 'succeeded' && prevStatus.add === 'pending') {
            toast.success('File added successfully!');
            setPrevStatus({ ...prevStatus, add: addStat });
        } else if (deleteStat === 'succeeded' && prevStatus.delete === 'pending') {
            toast.success('Removed successfully!');
            setPrevStatus({ ...prevStatus, delete: deleteStat });
        } else if (updateStatus === 'succeeded' && prevStatus.update === 'pending') {
            toast.success('Location pinned successfully!');
            setPrevStatus({ ...prevStatus, update: updateStatus });
        } else {
            setPrevStatus({ add: addStat, delete: deleteStat, update: updateStatus });
        }
    }, [addStat, deleteStat, updateStatus]);

    useEffect(() => {
        dispatch(getUploads());
    }, [dispatch, deleteStat, addStat, updateStatus]);

    const handleDelete = (id) => {
        dispatch(deleteUploads(id));
    };

    const handleDownload = (file) => {
        const link = document.createElement('a');
        link.href = file;
        link.download = file;
        link.click();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (addStat === 'succeeded') {
            setCatalogModalOpen(false);
        } else if (updateStatus === 'succeeded') {

            setLocationModalOpen(false);
        } else {

        }
    }, [addStat, updateStatus]);

    const isPending = getStat === 'pending' || deleteStat === 'pending' || addStat === 'pending';

    return (
        <>
            <Topbar activeTab={'downloads or showroom'} />
            {isPending && (
                <Loader />
            )}
            <ToastContainer theme='dark' />
            <Container component="main">
                <Box
                    sx={{
                        bgcolor: '#1f1f2e',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
                        borderRadius: 3,
                        p: 5,
                        mt: 4,
                        color: 'white',
                        // maxWidth: '900px',
                        mx: 'auto',
                    }}
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#a4c639' }}>
                            File Uploads
                        </Typography>
                        <Box>
                            <Button
                                variant="contained"
                                sx={{
                                    bgcolor: '#ff7043',
                                    color: 'white',
                                    mr: 2,
                                    borderRadius: 3,
                                    boxShadow: '0px 4px 10px rgba(255, 112, 67, 0.3)',
                                    '&:hover': {
                                        bgcolor: '#ff8a65',
                                    },
                                }}
                                onClick={() => setLocationModalOpen(true)}
                            >
                                Update Location
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    bgcolor: '#4caf50',
                                    color: 'white',
                                    borderRadius: 3,
                                    boxShadow: '0px 4px 10px rgba(66, 165, 245, 0.3)',
                                    '&:hover': {
                                        bgcolor: '#64b5f6',
                                    },
                                }}
                                onClick={() => setCatalogModalOpen(true)}
                            >
                                Upload Files
                            </Button>
                        </Box>
                    </Box>

                    {/* <Snackbar open={getStat === 'pending'} autoHideDuration={6000}>
                        <Alert severity="info" sx={{ bgcolor: '#4caf50', color: 'white' }}>Loading uploads data...</Alert>
                    </Snackbar> */}

                    <TableContainer component={Paper} sx={{ bgcolor: '#28293e', borderRadius: 2, mt: 3, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>Title</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>Description</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>File</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {uploadsData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((upload) => (
                                        <TableRow key={upload.id} sx={{ '&:hover': { bgcolor: '#393a4d' } }}>
                                            <TableCell sx={{ color: '#e0e0e0' }}>{upload.title}</TableCell>
                                            <TableCell sx={{ color: '#e0e0e0', maxWidth: '200px' }}>
                                                <Tooltip title={upload.description} arrow>
                                                    <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {upload.description}
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<DownloadIcon />}
                                                    onClick={() => handleDownload(upload.catalog)}
                                                    sx={{
                                                        color: '#64b5f6',
                                                        height: '40px',
                                                        width: '40px',
                                                        borderColor: '#64b5f6',
                                                        '&:hover': {
                                                            bgcolor: '#64b5f6',
                                                            color: 'white',
                                                        },
                                                    }}
                                                >

                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    sx={{
                                                        color: '#e57373',
                                                        '&:hover': {
                                                            bgcolor: '#ffcdd2',
                                                        },
                                                    }}
                                                    onClick={() => handleDelete(upload.id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={uploadsData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                bgcolor: '#28293e',
                                color: '#b0bec5',
                                '& .MuiTablePagination-actions button': { color: '#b0bec5' },
                            }}
                        />
                    </TableContainer>
                </Box>
            </Container>


            <LocationUpdateModal open={locationModalOpen} onClose={() => setLocationModalOpen(false)} />
            <CatalogUploadModal open={catalogModalOpen} onClose={() => setCatalogModalOpen(false)} />
        </>
    );
};

export default Banner;
