import React from 'react'
import AdminTopbar from '../Layout/AdminTopbar'
import StockoutReport from '../../Report/StockoutReport'

const OutReport = () => {
    return (
        <>
            <AdminTopbar activeTab={'Stock out report'} />
            <StockoutReport />
        </>
    )
}

export default OutReport