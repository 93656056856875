import React from 'react'
import Category from '../../SuperAdmin/Category/Category'
import AdminTopbar from '../Layout/AdminTopbar'

const Categories = () => {
    return (
        <>
            <AdminTopbar activeTab={'categories'} />
            <Category />
        </>
    )
}

export default Categories