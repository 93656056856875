import React, { useEffect, useState } from 'react';
import { getBrand, addBrand, updateBrand, deleteBrand } from '../../../../redux/Slices/SuperAdmin/inventory/brandSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableView from '../../../../components/utils/TableView';
import Topbar from '../../../../components/Layout/Topbar';

const Brand = () => {

    const { brandData = [], updateStatus, getStatus, addStatus, deleteStatus } = useSelector(state => state.superAdminBrand);
    const dispatch = useDispatch();
    const { roleId } = useSelector((state) => state.auth);


    useEffect(() => {
        dispatch(getBrand());
    }, [dispatch, updateStatus, addStatus, deleteStatus]);

    return (
        <>
            {(roleId === '1' || roleId === '4') && (

                < Topbar activeTab={'brands'} />
            )}

            <TableView
                data={brandData}
                deleteThunk={deleteBrand}
                updateThunk={updateBrand}
                addThunk={addBrand}
                addStatus={addStatus}
                deleteStatus={deleteStatus}
                updateStatus={updateStatus}
                getStatus={getStatus}
            />

        </>
    );
};

export default Brand;