import React from 'react'
import Brand from '../../SuperAdmin/Inventory/Brand/Brand'
import AdminTopbar from '../Layout/AdminTopbar'

const Brands = () => {
    return (
        <>
            <AdminTopbar activeTab={'Brand'} />
            <Brand />
        </>
    )
}

export default Brands